import React, { useContext, useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import {
  default as CurrencyFormat,
  default as NumberFormat,
} from "react-currency-format"
import { useDispatch, useSelector } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import CashImage from "../../../svg/cash"
import ContactlessImage from "../../../svg/contactless"
import OnlineImage from "../../../svg/online"
import PosImage from "../../../svg/pos"
import ApiIds from "../../../../auth/ApiIds"
import CardService from "../../../../services/CardService"
import {
  clearCommonState,
  setCommonState,
} from "../../../../store/actions/Common"
import { setdisabledFunctionsByBlockCode } from "../../../../store/actions/Session"
import { setCards } from "../../../../store/actions/User"
import * as analytics from "../../../../utils/analytics"
import { handleApiAuth } from "../../../../utils/auth"
import {
  DisplayConfig,
  ErrorType,
  INTEGER_REGEX,
  ProgramTypes,
} from "../../../../utils/constants"
import {
  AU_BlockCodes,
  BottomSheetType,
  CardPrimaryPeriodType,
  EventName,
  Issuers,
  KycStatus,
  PwaVersions,
} from "../../../../utils/enums"
import {
  captureEvents,
  consoleError,
  getAnalyticsProgramType,
  hideGenericError,
  resetErrorTryAgainCount,
} from "../../../../utils/functions"
import { Header } from "../../../core"
import { ColorButton, WhiteButton } from "../../../core/buttons"
import ErrorScreen from "../../../messages/errorScreen"
import styles from "./styles"
import CardControlsLoader from "../../../core/SkeletonLoader/CardControlsLoader"
import ArrowLeft from "../../../svg/arrowLeft"
import OfflineIcon from "../../../svg/v3/OfflineIcon"
import CashIcon from "../../../svg/v3/CashIcon"
import ContactlessIcon from "../../../svg/v3/ContactlessIcon"
import CardSettingsDomestic from "../../../svg/v3/CardSettingsDomestic"
import CardSettingsInternational from "../../../svg/v3/CardSettingsInternational"
import Switch from "react-switch"
import FilledInfoCircle from "../../../svg/v3/filledInfoCircle"
import { useBottomSheet } from "../../../auth/BottomSheetContext"
import WithdrawalIcon from "../../../svg/v3/WithdrawalIcon"
import InfoPromptV3 from "../../../core/InfoPromptV3"
import Slider from "../../../../nativeBaseReplacements/Slider"
import Input from "../../../../nativeBaseReplacements/Input"
import { View as KeyboardAvoidingView } from "../../../../nativeBaseReplacements"
import useWindowDimensions from "../../../../hooks/useWindowDimensionsWeb"
import { ErrorContext } from "../../../auth/ErrorScreenContext"

const sliderSteps = 1000
const grayColor = "#a8a5a5"

// analytics
const getAnalyticsLocation = location => {
  if (location === "domestic") return "Dom"
  else if (location === "international") return "Intl"
  else return "Undefined Location"
}

const getAnalyticsKey = key => {
  if (key === "online") return "Online Transaction"
  else if (key === "offline") return "Offline Transaction"
  else if (key === "contactless") return "Contactless"
  else if (key === "cash") return "Cash Withdrawal"
  else return "Undefined Mode"
}

const trackBackClick = programType => {
  analytics.track(
    `${getAnalyticsProgramType(
      programType,
    )} - Click on back button (Card Controls)`,
  )
}

const trackCancelClick = programType => {
  analytics.track(
    `${getAnalyticsProgramType(programType)} - Click on Cancel (Card Controls)`,
  )
}

const trackCardStatus = (programType, cardStatus) => {
  analytics.track(
    `${getAnalyticsProgramType(programType)} - Click on Card Status`,
    {
      status: cardStatus ? "on" : "off",
    },
  )
}

const trackToggle = (programType, location, key, isEnabled, limit) => {
  const name = `${getAnalyticsProgramType(
    programType,
  )} - ${getAnalyticsLocation(location)} - ${getAnalyticsKey(key)}`

  analytics.track(name, {
    status: isEnabled ? "on" : "off",
    limit,
  })
}

const trackLimit = (programType, location, key, limit) => {
  const name = `${getAnalyticsProgramType(
    programType,
  )} - ${getAnalyticsLocation(location)} - limit - ${getAnalyticsKey(key)}`

  analytics.track(name, { limit: Number(limit) })
}

const getCardStatus = cardStatus => {
  return cardStatus ? "on" : "off"
}
const trackSaveClick = (programType, cardStatus, cardSetting) => {
  const isPaylaterOrPrepaidProgram =
    programType === ProgramTypes.PAY_LATER ||
    programType === ProgramTypes.PREPAID

  const name = `${getAnalyticsProgramType(
    programType,
  )} - Click on Save Changes (Card Controls)`

  let props = {
    "Card Status": getCardStatus(cardStatus),

    [getAnalyticsLocation("domestic")]: {
      [getAnalyticsKey("online")]: {
        status: cardSetting.domestic.online?.isEnabled ? "on" : "off",
        limit: Number(cardSetting.domestic.online.dailyLimit),
      },
      [getAnalyticsKey("offline")]: {
        status: cardSetting.domestic.offline?.isEnabled ? "on" : "off",
        limit: Number(cardSetting.domestic.offline.dailyLimit),
      },
      [getAnalyticsKey("contactless")]: {
        status: cardSetting.domestic.contactless?.isEnabled ? "on" : "off",
        limit: Number(cardSetting.domestic.contactless.dailyLimit),
      },
    },
  }

  if (!isPaylaterOrPrepaidProgram) {
    props = {
      ...props,
      [getAnalyticsLocation("domestic")]: {
        ...props[getAnalyticsLocation("domestic")],
        [getAnalyticsKey("cash")]: {
          status: cardSetting.domestic.cash?.isEnabled ? "on" : "off",
          limit: Number(cardSetting.domestic.cash.dailyLimit),
        },
      },
      [getAnalyticsLocation("international")]: {
        [getAnalyticsKey("online")]: {
          status: cardSetting.international.online?.isEnabled ? "on" : "off",
          limit: Number(cardSetting.international.online.dailyLimit),
        },
        [getAnalyticsKey("offline")]: {
          status: cardSetting.international.offline?.isEnabled ? "on" : "off",
          limit: Number(cardSetting.international.offline.dailyLimit),
        },
        [getAnalyticsKey("contactless")]: {
          status: cardSetting.international.contactless?.isEnabled
            ? "on"
            : "off",
          limit: Number(cardSetting.international.contactless.dailyLimit),
        },
        [getAnalyticsKey("cash")]: {
          status: cardSetting.international.cash?.isEnabled ? "on" : "off",
          limit: Number(cardSetting.international.cash.dailyLimit),
        },
      },
    }
  }

  analytics.track(name, props)
}

const CardSettings_v3 = () => {
  const { t } = useTranslation()
  const { setErrorState } = useContext(ErrorContext)
  const navigate = useNavigate()
  const windowDim = useWindowDimensions()

  const dispatch = useDispatch()
  const theme = useSelector(state => state.theme)
  const user = useSelector(state => state.user)
  const common = useSelector(state => state.common)
  const config = useSelector(state => state.config)
  const session = useSelector(state => state.session)
  const screen = useSelector(state => state.screen)
  const isPhysicalCardActivated = user?.cards[0]?.isPhysicalCardActivated
  const isVirtualCardActivated = user?.cards[0]?.isVirtualCardActivated
  const cardTypeVirtual = user?.cards[0]?.cardType === "Virtual"
  const bottomSheetContext = useBottomSheet()

  const onlyVirtual =
    isVirtualCardActivated && (cardTypeVirtual || !isPhysicalCardActivated)
  const notPhysicalAndVirtual =
    !isVirtualCardActivated && !isPhysicalCardActivated

  let primaryPeriodType = screen?.cardControls?.primaryPeriodType

  const limitSliderStep = screen?.cardControls?.silderSteps || sliderSteps
  if (primaryPeriodType === CardPrimaryPeriodType.MONTHLY) {
    primaryPeriodType = "Monthly Transactions"
  } else if (primaryPeriodType === CardPrimaryPeriodType.TRANSACTIONAL) {
    primaryPeriodType = "Per Transaction"
  } else {
    primaryPeriodType = "Daily Transactions"
  }

  const kycStatus = user?.customer?.kycStatus

  const account = user.account
  const card = user.cards?.[0]

  let approvedCashLimit = account.approvedCashLimit
  let approvedCreditLimit = account.approvedCreditLimit

  // common store vars
  const { isCardSettingsSet, fetchCardData, fetchCardControlsData } = common

  const isAccountActive = account.status === "ACTIVE"

  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [isDomesticSelected, setIsDomesticSelected] = useState(true)

  const [disableAllControls, setDisableAllControls] = useState(false)

  const [cardStatus, setCardStatus] = useState(true)
  const [cardSetting, setCardSetting] = useState()
  const inputWidthRef = useRef(60)
  const cardSettingsContainerRef = useRef(null)
  let initCardSettings = useRef({})

  const isPaylaterOrPrepaidProgram =
    user.programType === ProgramTypes.PAY_LATER ||
    user.programType === ProgramTypes.PREPAID

  useEffect(() => {
    if (notPhysicalAndVirtual) {
      navigate("/", { replace: true })
      captureEvents({
        eventName: EventName.CARD_CONTROLS_ERROR,
        metadata: { msg: t("settings.cardSettingsAccessBlocked") },
      })
    }

    if (
      session.disabledFunctionsByBlockCode.includes(
        AU_BlockCodes.CARD_DASHBOARD_DISABLE,
      ) ||
      session?.disabledFunctionsByBlockCode?.includes(
        AU_BlockCodes.UNBLOCK_CARD_DISABLE,
      )
    ) {
      captureEvents({
        eventName: EventName.CARD_CONTROLS_ERROR,
        metadata: { msg: t("settings.cardSettingsAccessBlocked") },
      })
      navigate("/")
    }

    if (
      session.disabledFunctionsByBlockCode.includes(
        AU_BlockCodes.TEMP_CARD_DISABLE,
      ) ||
      session.disabledFunctionsByBlockCode.includes(
        AU_BlockCodes.PERMANENT_CARD_DISABLE,
      )
    ) {
      setDisableAllControls(true)
    }
    const element = cardSettingsContainerRef.current
    element.classList.add("active")
    return () => {
      element.classList.remove("active")
    }
  }, [])

  useEffect(() => {
    if (
      user.account.status !== "ACTIVE" ||
      !user.cards[0] ||
      user.cards[0].isHotlisted
    ) {
      navigate("/")
    }
  }, [user.account.status, user.cards, navigate])

  useEffect(() => {
    if (notPhysicalAndVirtual) {
      navigate("/", { replace: true })
      captureEvents({
        eventName: EventName.CARD_CONTROLS_ERROR,
        metadata: { msg: t("settings.cardSettingsAccessBlocked") },
      })
    }

    ;(async () => {
      if (!isCardSettingsSet) {
        // if state is not set in store

        // clear common store
        dispatch(clearCommonState())

        // call init apis
        await handleApiAuth({
          apiId: ApiIds.FETCH_CARD,
          onAuthSuccess: onFetchCardAuthSuccess,
          onAuthFailure: onAuthFailure,
          onAuthCancel: onAuthCancel,
          otpReason: t("CardDetailsWidget.toGetCardDetails"),
          mpinReason: t("CardDetailsWidget.toGetCardDetails"),
          version: config?.version,
          bottomSheetContext: bottomSheetContext,
        })
      } else {
        const isLocked = fetchCardData.isLocked
        const cardControl = fetchCardControlsData

        // get current config
        let initConfig = {
          domesticOnline: cardControl.filter(item => {
            return (
              item.labelsMap.SOVEREIGNTY_INDICATOR === "DOMESTIC" &&
              item.labelsMap.MCC === "RETAIL" &&
              item.labelsMap.POS_ENTRY_MODE === "ECOMMERCE"
            )
          })[0],
          domesticOffline: cardControl.filter(item => {
            return (
              item.labelsMap.SOVEREIGNTY_INDICATOR === "DOMESTIC" &&
              item.labelsMap.MCC === "RETAIL" &&
              item.labelsMap.POS_ENTRY_MODE === "CONTACT"
            )
          })[0],
          domesticContactless: cardControl.filter(item => {
            return (
              item.labelsMap.SOVEREIGNTY_INDICATOR === "DOMESTIC" &&
              item.labelsMap.MCC === "RETAIL" &&
              item.labelsMap.POS_ENTRY_MODE === "CONTACTLESS"
            )
          })[0],
        }

        if (!isPaylaterOrPrepaidProgram) {
          initConfig = {
            ...initConfig,
            domesticCash: cardControl.filter(item => {
              return (
                item.labelsMap.SOVEREIGNTY_INDICATOR === "DOMESTIC" &&
                item.labelsMap.MCC === "CASH" &&
                item.labelsMap.POS_ENTRY_MODE === undefined
              )
            })[0],
            internationalOnline: cardControl.filter(item => {
              return (
                item.labelsMap.SOVEREIGNTY_INDICATOR === "INTERNATIONAL" &&
                item.labelsMap.MCC === "RETAIL" &&
                item.labelsMap.POS_ENTRY_MODE === "ECOMMERCE"
              )
            })[0],
            internationalOffline: cardControl.filter(item => {
              return (
                item.labelsMap.SOVEREIGNTY_INDICATOR === "INTERNATIONAL" &&
                item.labelsMap.MCC === "RETAIL" &&
                item.labelsMap.POS_ENTRY_MODE === "CONTACT"
              )
            })[0],
            internationalContactless: cardControl.filter(item => {
              return (
                item.labelsMap.SOVEREIGNTY_INDICATOR === "INTERNATIONAL" &&
                item.labelsMap.MCC === "RETAIL" &&
                item.labelsMap.POS_ENTRY_MODE === "CONTACTLESS"
              )
            })[0],
            internationalCash: cardControl.filter(item => {
              return (
                item.labelsMap.SOVEREIGNTY_INDICATOR === "INTERNATIONAL" &&
                item.labelsMap.MCC === "CASH" &&
                item.labelsMap.POS_ENTRY_MODE === undefined
              )
            })[0],
          }
        }

        // save current config
        initCardSettings.current = {
          cardStatus: !isLocked,

          domestic: {
            online: {
              isEnabled: initConfig?.domesticOnline?.isEnabled,
              dailyLimit: initConfig?.domesticOnline?.dailyTxnLimit?.limit || 0,
            },
            offline: {
              isEnabled: initConfig.domesticOffline?.isEnabled,
              dailyLimit: initConfig.domesticOffline?.dailyTxnLimit?.limit || 0,
            },
            contactless: {
              isEnabled: initConfig.domesticContactless?.isEnabled,
              dailyLimit:
                initConfig.domesticContactless?.dailyTxnLimit?.limit || 0,
            },
          },
        }

        if (!isPaylaterOrPrepaidProgram) {
          initCardSettings.current.domestic.cash = {
            isEnabled: initConfig.domesticCash?.isEnabled,
            dailyLimit: initConfig.domesticCash?.dailyTxnLimit?.limit || 0,
          }

          initCardSettings.current.international = {
            online: {
              isEnabled: initConfig.internationalOnline?.isEnabled,
              dailyLimit:
                initConfig.internationalOnline?.dailyTxnLimit?.limit || 0,
            },
            offline: {
              isEnabled: initConfig.internationalOffline?.isEnabled,
              dailyLimit:
                initConfig.internationalOffline?.dailyTxnLimit?.limit || 0,
            },
            contactless: {
              isEnabled: initConfig.internationalContactless?.isEnabled,
              dailyLimit:
                initConfig.internationalContactless?.dailyTxnLimit?.limit || 0,
            },
            cash: {
              isEnabled: initConfig.internationalCash?.isEnabled,
              dailyLimit:
                initConfig.internationalCash?.dailyTxnLimit?.limit || 0,
            },
          }
        }

        // set lock in state
        setCardStatus(!fetchCardData.isLocked)

        // set settings in state
        setCardSetting(() => {
          const setting = {
            domestic: {
              online: {
                label: t("activation.cardControlTitleOnlineTransactions"),
                ...initCardSettings.current.domestic.online,
              },
              offline: {
                label: t("activation.cardControlTitleOfflineTransactions"),
                ...initCardSettings.current.domestic.offline,
              },
              contactless: {
                label: t("activation.cardControlTitleContactless"),
                ...initCardSettings.current.domestic.contactless,
              },
            },
          }

          if (!isPaylaterOrPrepaidProgram) {
            setting.domestic.cash = {
              label: t("activation.cardControlTitleCashWithdrawal"),
              ...initCardSettings.current.domestic.cash,
            }

            setting.international = {
              online: {
                label: t("activation.cardControlTitleOnlineTransactions"),
                ...initCardSettings.current.international.online,
              },
              offline: {
                label: t("activation.cardControlTitleOfflineTransactions"),
                ...initCardSettings.current.international.offline,
              },
              contactless: {
                label: t("activation.cardControlTitleContactless"),
                ...initCardSettings.current.international.contactless,
              },
              cash: {
                label: t("activation.cardControlTitleCashWithdrawal"),
                ...initCardSettings.current.international.cash,
              },
            }
          }

          captureEvents({
            eventName: EventName.CARD_SETTINGS,
            metadata: {
              // settings: setting,
              // cardLocked: fetchCardData.isLocked + "",
            },
          })
          return setting
        })
      }
      setLoading(false)
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPaylaterOrPrepaidProgram, isCardSettingsSet])

  const handleFailureState = () => {
    bottomSheetContext.openBottomSheet(
      BottomSheetType.FAILURE,
      t("EmiElement.BottomSheetFailureMsg"),
      t("settings.updateTrxnLimitError"),
      null,
      null,
      () => handleSubmitClick(),
    )
  }

  const haveCardControlsChanged = () => {
    return (
      // check domestic
      initCardSettings.current.domestic.online?.isEnabled !==
        cardSetting.domestic.online?.isEnabled ||
      initCardSettings.current.domestic.online.dailyLimit !==
        Number(cardSetting.domestic.online.dailyLimit) ||
      initCardSettings.current.domestic.offline?.isEnabled !==
        cardSetting.domestic.offline?.isEnabled ||
      initCardSettings.current.domestic.offline.dailyLimit !==
        Number(cardSetting.domestic.offline.dailyLimit) ||
      initCardSettings.current.domestic.contactless?.isEnabled !==
        cardSetting.domestic.contactless?.isEnabled ||
      initCardSettings.current.domestic.contactless.dailyLimit !==
        Number(cardSetting.domestic.contactless.dailyLimit) ||
      (!isPaylaterOrPrepaidProgram &&
        initCardSettings.current.domestic.cash?.isEnabled !==
          cardSetting.domestic.cash?.isEnabled) ||
      (!isPaylaterOrPrepaidProgram &&
        initCardSettings.current.domestic.cash.dailyLimit !==
          Number(cardSetting.domestic.cash.dailyLimit)) ||
      // check international
      (!isPaylaterOrPrepaidProgram &&
        initCardSettings.current.international.online?.isEnabled !==
          cardSetting.international.online?.isEnabled) ||
      (!isPaylaterOrPrepaidProgram &&
        initCardSettings.current.international.online.dailyLimit !==
          Number(cardSetting.international.online.dailyLimit)) ||
      (!isPaylaterOrPrepaidProgram &&
        initCardSettings.current.international.offline?.isEnabled !==
          cardSetting.international.offline?.isEnabled) ||
      (!isPaylaterOrPrepaidProgram &&
        initCardSettings.current.international.offline.dailyLimit !==
          Number(cardSetting.international.offline.dailyLimit)) ||
      (!isPaylaterOrPrepaidProgram &&
        initCardSettings.current.international.contactless?.isEnabled !==
          cardSetting.international.contactless?.isEnabled) ||
      (!isPaylaterOrPrepaidProgram &&
        initCardSettings.current.international.contactless.dailyLimit !==
          Number(cardSetting.international.contactless.dailyLimit)) ||
      (!isPaylaterOrPrepaidProgram &&
        initCardSettings.current.international.cash?.isEnabled !==
          cardSetting.international.cash?.isEnabled) ||
      (!isPaylaterOrPrepaidProgram &&
        initCardSettings.current.international.cash.dailyLimit !==
          Number(cardSetting.international.cash.dailyLimit))
    )
  }

  const hasStateChanged = () => {
    if (
      !Object.keys(initCardSettings.current).length ||
      !Object.keys(cardSetting).length
    )
      return false

    return (
      // check lock
      initCardSettings.current.cardStatus !== cardStatus ||
      haveCardControlsChanged()
    )
  }

  const areControlsValid = () => {
    if (
      user.programType !== ProgramTypes.PREPAID &&
      approvedCreditLimit &&
      approvedCashLimit
    ) {
      if (
        (cardSetting.domestic.online?.isEnabled &&
          Number(cardSetting.domestic.online.dailyLimit) >
            approvedCreditLimit) ||
        (cardSetting.domestic.offline?.isEnabled &&
          Number(cardSetting.domestic.offline.dailyLimit) > approvedCreditLimit)
      ) {
        captureEvents({
          eventName: EventName.CARD_CONTROLS_ERROR,
          metadata: {
            msg: t("transactions.transactionLimitExceededText", {
              approvedCreditLimit: approvedCreditLimit,
            }),
          },
        })
        return false
      }
      if (
        cardSetting.domestic.cash?.isEnabled &&
        Number(cardSetting.domestic.cash.dailyLimit) > approvedCashLimit
      ) {
        captureEvents({
          eventName: EventName.CARD_CONTROLS_ERROR,
          metadata: {
            msg: t("transactions.cashTransactionLimitExceededText", {
              approvedCashLimit: approvedCashLimit,
            }),
          },
        })
        return false
      }
      if (
        (cardSetting.international.online?.isEnabled &&
          Number(cardSetting.international.online.dailyLimit) >
            approvedCreditLimit) ||
        (cardSetting.international.offline?.isEnabled &&
          Number(cardSetting.international.offline.dailyLimit) >
            approvedCreditLimit)
      ) {
        captureEvents({
          eventName: EventName.CARD_CONTROLS_ERROR,
          metadata: {
            msg: t("transactions.transactionLimitExceededText", {
              approvedCreditLimit: approvedCreditLimit,
            }),
          },
        })
        return false
      }
      if (
        cardSetting.international.cash?.isEnabled &&
        Number(cardSetting.international.cash.dailyLimit) > approvedCashLimit
      ) {
        captureEvents({
          eventName: EventName.CARD_CONTROLS_ERROR,
          metadata: {
            msg: t("transactions.cashTransactionLimitExceededText", {
              approvedCashLimit: approvedCashLimit,
            }),
          },
        })
        return false
      }

      if (
        cardSetting.international.contactless?.isEnabled &&
        Number(cardSetting.international.contactless.dailyLimit) >
          approvedCreditLimit
      ) {
        captureEvents({
          eventName: EventName.CARD_CONTROLS_ERROR,
          metadata: {
            msg: t("transactions.contactlessLimitExceededText", {
              approvedCreditLimit: approvedCreditLimit,
            }),
          },
        })
        return false
      }

      if (
        cardSetting.domestic.contactless?.isEnabled &&
        Number(cardSetting.domestic.contactless.dailyLimit) >
          approvedCreditLimit
      ) {
        captureEvents({
          eventName: EventName.CARD_CONTROLS_ERROR,
          metadata: {
            msg: t("transactions.contactlessLimitExceededText", {
              approvedCreditLimit: approvedCreditLimit,
            }),
          },
        })
        return false
      }
    } else if (
      user.programType === ProgramTypes.PREPAID ||
      user.programType === ProgramTypes.PAY_LATER
    ) {
      let transLimit = 10000
      if (kycStatus === KycStatus.FULL_KYC) {
        transLimit = 200000
      }

      if (
        cardSetting.domestic.online?.isEnabled &&
        Number(cardSetting.domestic.online.dailyLimit) > transLimit
      ) {
        captureEvents({
          eventName: EventName.CARD_CONTROLS_ERROR,
          metadata: {
            msg: t("transactions.transactionLimitExceededText", {
              approvedCreditLimit: transLimit,
            }),
          },
        })
        return false
      }
      if (
        cardSetting.domestic.offline?.isEnabled &&
        Number(cardSetting.domestic.offline.dailyLimit) > transLimit
      ) {
        captureEvents({
          eventName: EventName.CARD_CONTROLS_ERROR,
          metadata: {
            msg: t("transactions.transactionLimitExceededText", {
              approvedCreditLimit: transLimit,
            }),
          },
        })
        return false
      }
      if (
        cardSetting.domestic.contactless?.isEnabled &&
        Number(cardSetting.domestic.contactless.dailyLimit) > transLimit
      ) {
        captureEvents({
          eventName: EventName.CARD_CONTROLS_ERROR,
          metadata: {
            msg: t("transactions.contactlessLimitExceededText", {
              approvedCashLimit: transLimit,
            }),
          },
        })
        return false
      }
    }

    if (
      cardSetting.domestic.online?.isEnabled &&
      Number(cardSetting.domestic.online.dailyLimit) % limitSliderStep !== 0
    ) {
      captureEvents({
        eventName: EventName.CARD_CONTROLS_ERROR,
        metadata: {
          msg: t("transactions.transactionLimitMultipleText", {
            limitSliderStep,
          }),
        },
      })
      return false
    }

    if (
      cardSetting.domestic.offline?.isEnabled &&
      Number(cardSetting.domestic.offline.dailyLimit) % limitSliderStep !== 0
    ) {
      captureEvents({
        eventName: EventName.CARD_CONTROLS_ERROR,
        metadata: {
          msg: t("transactions.transactionLimitMultipleTextCopy", {
            limitSliderStep: limitSliderStep,
          }),
        },
      })
      return false
    }

    if (
      cardSetting.domestic.contactless?.isEnabled &&
      Number(cardSetting.domestic.contactless.dailyLimit) % limitSliderStep !==
        0
    ) {
      captureEvents({
        eventName: EventName.CARD_CONTROLS_ERROR,
        metadata: {
          msg: t("transactions.transactionLimitMultipleTextCopy", {
            limitSliderStep: limitSliderStep,
          }),
        },
      })

      return false
    }

    if (!isPaylaterOrPrepaidProgram) {
      if (
        cardSetting.domestic.cash.isEnabled &&
        Number(cardSetting.domestic.cash.dailyLimit) % limitSliderStep !== 0
      ) {
        captureEvents({
          eventName: EventName.CARD_CONTROLS_ERROR,
          metadata: {
            msg: t("transactions.transactionLimitMultipleTextCopy", {
              limitSliderStep: limitSliderStep,
            }),
          },
        })

        return false
      }

      if (
        cardSetting.international.online?.isEnabled &&
        Number(cardSetting.international.online.dailyLimit) %
          limitSliderStep !==
          0
      ) {
        captureEvents({
          eventName: EventName.CARD_CONTROLS_ERROR,
          metadata: {
            msg: t("transactions.transactionLimitMultipleTextCopy", {
              limitSliderStep: limitSliderStep,
            }),
          },
        })

        return false
      }

      if (
        cardSetting.international.offline?.isEnabled &&
        Number(cardSetting.international.offline.dailyLimit) %
          limitSliderStep !==
          0
      ) {
        captureEvents({
          eventName: EventName.CARD_CONTROLS_ERROR,
          metadata: {
            msg: t("transactions.transactionLimitMultipleTextCopy", {
              limitSliderStep: limitSliderStep,
            }),
          },
        })

        return false
      }

      if (
        cardSetting.international.contactless?.isEnabled &&
        Number(cardSetting.international.contactless.dailyLimit) %
          limitSliderStep !==
          0
      ) {
        captureEvents({
          eventName: EventName.CARD_CONTROLS_ERROR,
          metadata: {
            msg: t("transactions.transactionLimitMultipleTextCopy", {
              limitSliderStep: limitSliderStep,
            }),
          },
        })

        return false
      }

      if (
        cardSetting.international.cash?.isEnabled &&
        Number(cardSetting.international.cash.dailyLimit) % limitSliderStep !==
          0
      ) {
        captureEvents({
          eventName: EventName.CARD_CONTROLS_ERROR,
          metadata: {
            msg: t("transactions.transactionLimitMultipleTextCopy", {
              limitSliderStep: limitSliderStep,
            }),
          },
        })

        return false
      }
    }

    // check if card is unlocked but none of the toggles are enabled
    let areAllNonCCControlsDisabled =
      !cardSetting.domestic.online?.isEnabled &&
      !cardSetting.domestic.offline?.isEnabled &&
      !cardSetting.domestic.contactless?.isEnabled
    let areAllCCControlsDisabled = false
    if (!isPaylaterOrPrepaidProgram) {
      areAllCCControlsDisabled =
        !cardSetting.domestic.cash?.isEnabled &&
        !cardSetting.international.online?.isEnabled &&
        !cardSetting.international.offline?.isEnabled &&
        !cardSetting.international.contactless?.isEnabled &&
        !cardSetting.international.cash?.isEnabled
    }

    if (
      disableAllControls === false &&
      cardStatus &&
      ((isPaylaterOrPrepaidProgram && areAllNonCCControlsDisabled) ||
        (!isPaylaterOrPrepaidProgram &&
          areAllNonCCControlsDisabled &&
          areAllCCControlsDisabled))
    ) {
      captureEvents({
        eventName: EventName.CARD_CONTROLS_ERROR,
        metadata: { msg: "Please enable at least one setting" },
      })

      return false
    }

    return true
  }

  const handleSubmitClick = async () => {
    if (!areControlsValid()) return

    trackSaveClick(user.programType, cardStatus, cardSetting)
    setSubmitLoading(true)

    if (initCardSettings.current.cardStatus !== cardStatus) {
      // if lock status has changed
      // first call lock api, then its callback will call card controls api
      await handleApiAuth({
        apiId: cardStatus ? ApiIds.UNLOCK_CARD : ApiIds.LOCK_CARD,
        onAuthSuccess: onLockAuthSuccess,
        onAuthFailure: onAuthFailure,
        onAuthCancel: onAuthCancel,
        otpReason: t("CardDetailsWidgetV3.toChangeCardStatus"),
        mpinReason: t("CardDetailsWidgetV3.toChangeCardStatus"),
        version: config?.version,
        bottomSheetContext: bottomSheetContext,
      })
    } else {
      // if lock status didn't change and user was shown Save button then card controls must have changed
      // directly update card controls
      await handleApiAuth({
        apiId: ApiIds.UPDATE_CARD_CONTROLS,
        flowId: "3",
        onAuthSuccess: onControlsAuthSuccess,
        onAuthFailure: onAuthFailure,
        onAuthCancel: onAuthCancel,
        otpReason: t("settings.updateCardReason"),
        mpinReason: t("settings.updateCardReason"),
        version: config?.version,
        bottomSheetContext: bottomSheetContext,
      })
    }

    setSubmitLoading(false)
  }

  const onFetchCardAuthSuccess = async apiToken => {
    try {
      const response = await CardService.getCardDetails(
        apiToken,
        user.cards[0]?.id,
      )
      const result = response.data

      if (result?.success) {
        dispatch(setCommonState({ fetchCardData: result.data }))

        // call api for fetching card controls
        await handleApiAuth({
          apiId: ApiIds.FETCH_CARD_CONTROLS,
          onAuthSuccess: onFetchCardControlsAuthSuccess,
          onAuthFailure: onAuthFailure,
          onAuthCancel: onAuthCancel,
          otpReason: t("settings.getCardReason"),
          mpinReason: t("settings.getCardReason"),
          version: config?.version,
          bottomSheetContext: bottomSheetContext,
        })
        resetErrorTryAgainCount()
      } else {
        captureEvents({
          eventName: EventName.CARD_CONTROLS_ERROR,
          metadata: { msg: t("settings.loadingPageError") },
        })
        consoleError(result?.errors)
        if (config?.version === PwaVersions.V3) {
          setErrorState(response?.status)
        } else {
          setError(true)
        }
      }
    } catch (error) {
      if (!navigator.onLine) {
        setErrorState(ErrorType.NO_INTERNET_ERROR)
      } else {
        captureEvents({
          eventName: EventName.CARD_CONTROLS_ERROR,
          metadata: { msg: t("settings.loadingPageErrorCopy") },
        })

        consoleError(error)
        if (config?.version === PwaVersions.V3) {
          setErrorState(ErrorType.INTERNAL_ERROR)
        } else {
          setError(true)
        }
      }
    }
  }

  const onFetchCardControlsAuthSuccess = async apiToken => {
    try {
      const response = await CardService.getCardControls(
        apiToken,
        user.cards[0]?.id,
      )
      const result = response.data

      if (result?.success) {
        resetErrorTryAgainCount()
        // set flag and result in store
        dispatch(
          setCommonState({
            isCardSettingsSet: true,
            fetchCardControlsData: result.data,
          }),
        )

        // navigate back to this screen
        // if already on this screen (in case auth wasn't required) then navigate won't have any effect
        //only if allowed by block codes
        if (
          (session.disabledFunctionsByBlockCode.includes(
            AU_BlockCodes.CARD_DASHBOARD_DISABLE,
          ) ||
            session.disabledFunctionsByBlockCode?.includes(
              AU_BlockCodes.UNBLOCK_CARD_DISABLE,
            )) === false
        )
          navigate("/ManageCard/CardSettings", { replace: true })
      } else {
        consoleError(result?.errors)
        if (config?.version === PwaVersions.V3) {
          setErrorState(response?.status)
        } else {
          setError(true)
        }
      }
    } catch (error) {
      if (!navigator.onLine) {
        setErrorState(ErrorType.NO_INTERNET_ERROR)
      } else {
        consoleError(error)
        if (config?.version === PwaVersions.V3) {
          setErrorState(ErrorType.INTERNAL_ERRORl)
        } else {
          setError(true)
        }
      }
    }
  }

  const getTransactionText = label => {
    switch (label) {
      case t("activation.cardControlTitleOnlineTransactions"):
        return "Online Transactions"
      case t("activation.cardControlTitleOfflineTransactions"):
        return "POS/Offline Transactions"
      case t("activation.cardControlTitleContactless"):
        return "Contactless/NFC Transactions"
      case t("activation.cardControlTitleCashWithdrawal"):
        return "ATM Withdrawals"
    }
  }

  const onLockAuthSuccess = async apiToken => {
    try {
      const response = cardStatus
        ? await CardService.unlockCard(apiToken, user.cards[0]?.id)
        : await CardService.lockCard(apiToken, user.cards[0]?.id)
      const result = response?.data

      if (result?.success) {
        let eventName = cardStatus ? EventName.TEMP_UNLOCK : EventName.TEMP_LOCK
        captureEvents({ eventName: eventName, metadata: {} })

        const tempCard = { ...card }
        tempCard.isLocked = !cardStatus

        let disabledFunctions = session?.disabledFunctionsByBlockCode
        if (tempCard?.isLocked) {
          if (session?.issuer === Issuers.AU_BANK) {
            let index = disabledFunctions.indexOf(AU_BlockCodes.CARD_LOCKED)
            if (index === -1) {
              disabledFunctions.push(AU_BlockCodes.CARD_LOCKED)
            }
            dispatch(
              setdisabledFunctionsByBlockCode({
                disabledFunctionsByBlockCode: disabledFunctions,
              }),
            )
          }
        } else {
          if (session?.issuer === Issuers.AU_BANK) {
            let index = disabledFunctions.indexOf(AU_BlockCodes.CARD_LOCKED)
            if (index > -1) {
              disabledFunctions.splice(index, 1)
            }
            dispatch(
              setdisabledFunctionsByBlockCode({
                disabledFunctionsByBlockCode: disabledFunctions,
              }),
            )
          }
        }

        dispatch(setCards({ cards: [tempCard] }))

        if (haveCardControlsChanged()) {
          // call api for updating card controls
          await handleApiAuth({
            apiId: ApiIds.UPDATE_CARD_CONTROLS,
            flowId: "5",
            onAuthSuccess: onControlsAuthSuccess,
            onAuthFailure: onAuthFailure,
            onAuthCancel: onAuthCancel,
            otpReason: t("settings.updateCardReason"),
            mpinReason: t("settings.updateCardReason"),
            version: config?.version,
            bottomSheetContext: bottomSheetContext,
          })
        } else {
          bottomSheetContext.openBottomSheet(
            BottomSheetType.SUCCESS,
            t("settings.limitChangedSuccessMessage"),
            t("settings.dailyTransactionLimitUpdated"),
            "/",
          )
        }
        resetErrorTryAgainCount()
      } else {
        consoleError(result.errors)
        captureEvents({
          eventName: EventName.CARD_CONTROLS_ERROR,
          metadata: {
            msg: t("settings.updateCardControlError"),
          },
        })
        if (hideGenericError(response?.status)) {
          setErrorState(response?.status, () => handleSubmitClick())
        } else {
          handleFailureState()
        }
      }
    } catch (error) {
      if (!navigator.onLine) {
        setErrorState(ErrorType.NO_INTERNET_ERROR, () => handleSubmitClick())
      } else {
        consoleError(error)
        handleFailureState()
      }
    }
  }

  const onControlsAuthSuccess = async apiToken => {
    const controls = []

    if (
      initCardSettings.current.domestic.online?.isEnabled !==
        cardSetting.domestic.online?.isEnabled ||
      initCardSettings.current.domestic.online.dailyLimit !==
        Number(cardSetting.domestic.online.dailyLimit)
    ) {
      controls.push({
        txnTags: {
          location: "DOMESTIC",
          txnType: "RETAIL",
          txnChannel: "ECOMMERCE",
        },
        isEnabled:
          Number(cardSetting.domestic.online.dailyLimit) > 0 &&
          cardSetting.domestic.online?.isEnabled,
        dailyTxnLimit:
          Number(cardSetting.domestic.online.dailyLimit) > 0 &&
          cardSetting.domestic.online?.isEnabled
            ? Number(cardSetting.domestic.online.dailyLimit)
            : null,
      })
    }

    if (
      initCardSettings.current.domestic.offline?.isEnabled !==
        cardSetting.domestic.offline?.isEnabled ||
      initCardSettings.current.domestic.offline.dailyLimit !==
        Number(cardSetting.domestic.offline.dailyLimit)
    ) {
      controls.push({
        txnTags: {
          location: "DOMESTIC",
          txnType: "RETAIL",
          txnChannel: "CONTACT",
        },
        isEnabled:
          Number(cardSetting.domestic.offline.dailyLimit) > 0 &&
          cardSetting.domestic.offline?.isEnabled,
        dailyTxnLimit:
          Number(cardSetting.domestic.offline.dailyLimit) > 0 &&
          cardSetting.domestic.offline?.isEnabled
            ? Number(cardSetting.domestic.offline.dailyLimit)
            : null,
      })

      // need to set same config for magnetic
      controls.push({
        txnTags: {
          location: "DOMESTIC",
          txnType: "RETAIL",
          txnChannel: "MAGNETIC",
        },
        isEnabled:
          Number(cardSetting.domestic.offline.dailyLimit) > 0 &&
          cardSetting.domestic.offline?.isEnabled,
        dailyTxnLimit:
          Number(cardSetting.domestic.offline.dailyLimit) > 0 &&
          cardSetting.domestic.offline?.isEnabled
            ? Number(cardSetting.domestic.offline.dailyLimit)
            : null,
      })
    }

    if (
      initCardSettings.current.domestic.contactless?.isEnabled !==
        cardSetting.domestic.contactless?.isEnabled ||
      initCardSettings.current.domestic.contactless.dailyLimit !==
        Number(cardSetting.domestic.contactless.dailyLimit)
    ) {
      controls.push({
        txnTags: {
          location: "DOMESTIC",
          txnType: "RETAIL",
          txnChannel: "CONTACTLESS",
        },
        isEnabled:
          Number(cardSetting.domestic.contactless.dailyLimit) > 0 &&
          cardSetting.domestic.contactless?.isEnabled,
        dailyTxnLimit:
          Number(cardSetting.domestic.contactless.dailyLimit) > 0 &&
          cardSetting.domestic.contactless?.isEnabled
            ? Number(cardSetting.domestic.contactless.dailyLimit)
            : null,
      })
    }

    if (!isPaylaterOrPrepaidProgram) {
      if (
        initCardSettings.current.domestic.cash?.isEnabled !==
          cardSetting.domestic.cash?.isEnabled ||
        initCardSettings.current.domestic.cash.dailyLimit !==
          Number(cardSetting.domestic.cash.dailyLimit)
      ) {
        controls.push({
          txnTags: {
            location: "DOMESTIC",
            txnType: "CASH",
          },
          isEnabled:
            Number(cardSetting.domestic.cash.dailyLimit) > 0 &&
            cardSetting.domestic.cash?.isEnabled,
          dailyTxnLimit:
            Number(cardSetting.domestic.cash.dailyLimit) > 0 &&
            cardSetting.domestic.cash?.isEnabled
              ? Number(cardSetting.domestic.cash.dailyLimit)
              : null,
        })
      }

      if (
        initCardSettings.current.international.online?.isEnabled !==
          cardSetting.international.online?.isEnabled ||
        initCardSettings.current.international.online.dailyLimit !==
          Number(cardSetting.international.online.dailyLimit)
      ) {
        controls.push({
          txnTags: {
            location: "INTERNATIONAL",
            txnType: "RETAIL",
            txnChannel: "ECOMMERCE",
          },
          isEnabled:
            Number(cardSetting.international.online.dailyLimit) > 0 &&
            cardSetting.international.online?.isEnabled,
          dailyTxnLimit:
            Number(cardSetting.international.online.dailyLimit) > 0 &&
            cardSetting.international.online?.isEnabled
              ? Number(cardSetting.international.online.dailyLimit)
              : null,
        })
      }

      if (
        initCardSettings.current.international.offline?.isEnabled !==
          cardSetting.international.offline?.isEnabled ||
        initCardSettings.current.international.offline.dailyLimit !==
          Number(cardSetting.international.offline.dailyLimit)
      ) {
        controls.push({
          txnTags: {
            location: "INTERNATIONAL",
            txnType: "RETAIL",
            txnChannel: "CONTACT",
          },
          isEnabled:
            Number(cardSetting.international.offline.dailyLimit) > 0 &&
            cardSetting.international.offline?.isEnabled,
          dailyTxnLimit:
            Number(cardSetting.international.offline.dailyLimit) > 0 &&
            cardSetting.international.offline?.isEnabled
              ? Number(cardSetting.international.offline.dailyLimit)
              : null,
        })

        // need to set same config for magnetic
        controls.push({
          txnTags: {
            location: "INTERNATIONAL",
            txnType: "RETAIL",
            txnChannel: "MAGNETIC",
          },
          isEnabled:
            Number(cardSetting.international.offline.dailyLimit) > 0 &&
            cardSetting.international.offline?.isEnabled,
          dailyTxnLimit:
            Number(cardSetting.international.offline.dailyLimit) > 0 &&
            cardSetting.international.offline?.isEnabled
              ? Number(cardSetting.international.offline.dailyLimit)
              : null,
        })
      }

      if (
        initCardSettings.current.international.contactless?.isEnabled !==
          cardSetting.international.contactless?.isEnabled ||
        initCardSettings.current.international.contactless.dailyLimit !==
          Number(cardSetting.international.contactless.dailyLimit)
      ) {
        controls.push({
          txnTags: {
            location: "INTERNATIONAL",
            txnType: "RETAIL",
            txnChannel: "CONTACTLESS",
          },
          isEnabled:
            Number(cardSetting.international.contactless.dailyLimit) > 0 &&
            cardSetting.international.contactless?.isEnabled,
          dailyTxnLimit:
            Number(cardSetting.international.contactless.dailyLimit) > 0 &&
            cardSetting.international.contactless?.isEnabled
              ? Number(cardSetting.international.contactless.dailyLimit)
              : null,
        })
      }

      if (
        initCardSettings.current.international.cash?.isEnabled !==
          cardSetting.international.cash?.isEnabled ||
        initCardSettings.current.international.cash.dailyLimit !==
          Number(cardSetting.international.cash.dailyLimit)
      ) {
        controls.push({
          txnTags: {
            location: "INTERNATIONAL",
            txnType: "CASH",
          },
          isEnabled:
            Number(cardSetting.international.cash.dailyLimit) > 0 &&
            cardSetting.international.cash?.isEnabled,
          dailyTxnLimit:
            Number(cardSetting.international.cash.dailyLimit) > 0 &&
            cardSetting.international.cash?.isEnabled
              ? Number(cardSetting.international.cash.dailyLimit)
              : null,
        })
      }
    }

    const response = await CardService.updateCardControls(
      apiToken,
      user.cards[0]?.id,
      {
        updateCardControls: controls,
      },
    )
    const result = response?.data

    if (result?.success) {
      const analyticsControls = {
        onlineTransactionDomestic: cardSetting?.domestic?.online?.isEnabled
          ? "Enabled"
          : "Disabled",
        offlineTransactionDomestic: cardSetting?.domestic?.offline?.isEnabled
          ? "Enabled"
          : "Disabled",
        contactlessTransactionDomestic: cardSetting.domestic?.contactless
          ?.isEnabled
          ? "Enabled"
          : "Disabled",
        cashWithdrawalDomestic: cardSetting?.domestic?.cash?.isEnabled
          ? "Enabled"
          : "Disabled",
        onlineTransactionInternational: cardSetting?.international?.online
          ?.isEnabled
          ? "Enabled"
          : "Disabled",
        offlineTransactionInternational: cardSetting?.international?.offline
          ?.isEnabled
          ? "Enabled"
          : "Disabled",
        contactlessTransactionInternational: cardSetting?.international
          ?.contactless?.isEnabled
          ? "Enabled"
          : "Disabled",
        cashWithdrawalInternational: cardSetting?.international?.cash?.isEnabled
          ? "Enabled"
          : "Disabled",
      }
      captureEvents({
        eventName: EventName.UPDATE_CARD_CONTROLS,
        metadata: {
          controls: analyticsControls,
          cardLocked: result?.data?.isLocked + "",
        },
      })
      dispatch(setCards({ cards: [result.data] }))
      bottomSheetContext.openBottomSheet(
        BottomSheetType.SUCCESS,
        t("settings.limitChangedSuccessMessage"),
        t("settings.dailyTransactionLimitUpdated"),
        "/",
      )
      // navigate("/ManageCard", { replace: true })
    } else {
      consoleError(result.errors)

      const limitExceedErrors =
        result.errors?.filter(error =>
          error.reason.includes("dailyTxnLimit cannot exceed"),
        ) || []

      if (limitExceedErrors.length > 0) {
        const errorMsg = limitExceedErrors[0].reason.replace(
          "dailyTxnLimit",
          "Transaction limit",
        )
        captureEvents({
          eventName: EventName.CARD_CONTROLS_ERROR,
          metadata: { msg: errorMsg },
        })
      } else {
        captureEvents({
          eventName: EventName.CARD_CONTROLS_ERROR,
          metadata: { msg: t("settings.updateCardSettingsError") },
        })
      }

      handleFailureState()
      // navigate("/ManageCard/CardSettings", { replace: true })
    }
  }

  const onAuthFailure = async (error, message) => {
    error && consoleError(error)
    captureEvents({
      eventName: EventName.CARD_CONTROLS_ERROR,
      metadata: { msg: t("settings.internalErrorMesssage") },
    })

    handleFailureState()
    // navigate("/ManageCard", { replace: true })
  }

  const onAuthCancel = async message => {
    captureEvents({
      eventName: EventName.CARD_CONTROLS_ERROR,
      metadata: { msg: message },
    })

    handleFailureState()
    // navigate("/ManageCard", { replace: true })
  }

  const getImageIcon = control => {
    const { label, isEnabled } = control
    if (label === t("activation.cardControlTitleOnlineTransactions")) {
      return (
        <CashIcon
          className='cardSetting-online-transaction-icon'
          color1={
            isEnabled
              ? theme.v3.rawColors.primary.color1
              : theme.v3.rawColors.primaryBase.color2
          }
          color2={
            isEnabled
              ? theme.v3.rawColors.primary.color5
              : theme.v3.rawColors.primaryBase.color5
          }
          size='18px'
        />
      )
    } else if (label === t("activation.cardControlTitleOfflineTransactions")) {
      return (
        <OfflineIcon
          className='cardSetting-pos-transaction-icon'
          color1={
            isEnabled
              ? theme.v3.rawColors.primary.color1
              : theme.v3.rawColors.primaryBase.color2
          }
          color2={
            isEnabled
              ? theme.v3.rawColors.primary.color5
              : theme.v3.rawColors.primaryBase.color5
          }
          size='18px'
        />
      )
    } else if (label === t("activation.cardControlTitleContactless")) {
      return (
        <ContactlessIcon
          className='cardSetting-contactless-transaction-icon'
          color1={
            isEnabled
              ? theme.v3.rawColors.primary.color1
              : theme.v3.rawColors.primaryBase.color2
          }
          color2={
            isEnabled
              ? theme.v3.rawColors.primary.color5
              : theme.v3.rawColors.primaryBase.color5
          }
          size='18px'
        />
      )
    } else if (label === t("activation.cardControlTitleCashWithdrawal")) {
      return (
        <WithdrawalIcon
          className='cardSetting-cashWithdrawal-transaction-icon'
          color1={
            isEnabled
              ? theme.v3.rawColors.primary.color1
              : theme.v3.rawColors.primaryBase.color2
          }
          color2={
            isEnabled
              ? theme.v3.rawColors.primary.color5
              : theme.v3.rawColors.primaryBase.color5
          }
          size='18px'
        />
      )
    }
  }
  const getMaxLimit = control => {
    if (isPaylaterOrPrepaidProgram) {
      if (kycStatus === KycStatus.SMALL_KYC) {
        return 10000
      } else return 200000
    } else {
      if (control.label === t("activation.cardControlTitleCashWithdrawal"))
        return approvedCashLimit
      else return approvedCreditLimit
    }
  }
  const [checked, setChecked] = useState(false)
  const handleChange = nextChecked => {
    setChecked(nextChecked)
  }

  const getFontSize = () => {
    if (windowDim.width < 365) return "12px"
    else if (windowDim.width < 395) return "14px"
    else return "16px"
  }

  const getSettings = location => {
    // location can be domestic or international
    return (
      <div className='cardSetting-settings v3-cardSetting-settings'>
        {cardSetting !== undefined &&
          Object.entries(cardSetting[location]).map(([key, control], index) => {
            // key can be online, cash etc.
            if (control.dailyLimit.toString().length > 4) {
              inputWidthRef.current =
                control.dailyLimit?.toString()?.length * 10
            } else if (control.dailyLimit.toString().length == 4) {
              inputWidthRef.current =
                5 + control.dailyLimit?.toString()?.length * 10
            } else {
              inputWidthRef.current =
                10 + control.dailyLimit?.toString()?.length * 10
            }
            const calculateBorderBottomColor = () => {
              return !(control.dailyLimit % limitSliderStep)
                ? theme.v3.rawColors.primary.color1
                : theme.v3.rawColors.tertiaryNegative.color2
            }
            return (
              <div
                className='cardSetting-settings-container v3-cardSetting-settings-container'
                key={key}
              >
                <div
                  style={
                    !disableAllControls &&
                    control.isEnabled &&
                    !(
                      control.label !==
                        t("activation.cardControlTitleOnlineTransactions") &&
                      onlyVirtual
                    ) &&
                    cardStatus
                      ? {}
                      : { borderBottomWidth: "0px" }
                  }
                  className='cardSetting-settings-transaction-section-div v3-cardSetting-settings-transaction-section-div '
                >
                  <div
                    className='cardSetting-settings-transaction-section-icon'
                    style={{ marginBottom: 0 }}
                  >
                    {getImageIcon(control)}
                  </div>

                  <div
                    className={`cardSetting-settings-transaction-section-text cardSetting-settings-transaction-section-text-${
                      control?.label?.split(" ")[0]
                    } `}
                    style={{
                      color: getVersionedDisplay()
                        ? control.isEnabled
                          ? theme.v3.cssVars.primaryBase.color1
                          : theme.v3.cssVars.primaryBase.color2
                        : null,
                      fontFamily: theme.fontFamily,
                    }}
                  >
                    {getTransactionText(control.label)}
                  </div>
                  <Switch
                    onChange={() => {
                      cardStatus &&
                        setCardSetting(currCardSetting => ({
                          ...currCardSetting,
                          [location]: {
                            ...currCardSetting[location],
                            [key]: {
                              ...currCardSetting[location][key],
                              isEnabled:
                                !currCardSetting[location][key].isEnabled,
                            },
                          },
                        }))

                      trackToggle(
                        user.programType,
                        location,
                        key,
                        !cardSetting[location][key].isEnabled, // pass reverse of current toggle state
                        cardSetting[location][key].dailyLimit,
                      )
                    }}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    width={35}
                    height={20}
                    handleDiameter={16}
                    onColor={theme.v3.rawColors.primary.color1}
                    onHandleColor={theme.v3.rawColors.leadingWhite}
                    offColor={theme.v3.rawColors.primaryBase.color4}
                    offHandleColor={theme.v3.rawColors.leadingWhite}
                    disabled={
                      (disableAllControls &&
                        (cardStatus || disableAllControls)) ||
                      ((user.programType === ProgramTypes.PREPAID ||
                        getVersionedDisplay()) &&
                        isAccountActive &&
                        card &&
                        !card.isHotlisted &&
                        onlyVirtual &&
                        control.label !==
                          t("activation.cardControlTitleOnlineTransactions"))
                    }
                    checked={
                      cardStatus &&
                      control.isEnabled &&
                      !(
                        onlyVirtual &&
                        control.label !==
                          t("activation.cardControlTitleOnlineTransactions")
                      )
                    }
                  />
                </div>

                {!disableAllControls &&
                  control.isEnabled &&
                  !(
                    control.label !==
                      t("activation.cardControlTitleOnlineTransactions") &&
                    onlyVirtual
                  ) &&
                  cardStatus && (
                    <>
                      <div className='v3-card-settings-daily-limit-amount-div-container'>
                        <div className='v3-card-settings-daily-limit-amount-div'>
                          <div className='v3-card-settings-transactions-daily-limit-text'>
                            Daily Limit
                          </div>
                          <div
                            className='cardSetting-settings-transaction-amount-section v3-cardSetting-settings-transaction-amount-section'
                            w='40%'
                            maxW='200px'
                            mx='auto'
                          >
                            <div className='v3-cardSetting-transaction-amount-prefix'>
                              ₹{" "}
                            </div>
                            <CurrencyFormat
                              width={inputWidthRef.current + "%"}
                              className='curremctfen'
                              isAllowed={values =>
                                values?.value <= getMaxLimit(control)
                              }
                              allowNegative={false}
                              decimalScale={0}
                              size='md'
                              placeholder='NA'
                              textAlign={"center"}
                              thousandSpacing='2s'
                              thousandSeparator={true}
                              fontWeight='bold'
                              color={
                                // !control.isEnabled ||
                                // !cardStatus ||
                                // (control.label !== t("activation.cardControlTitleOnlineTransactions") &&
                                //   onlyVirtual)
                                //   ? `${theme.v3.}:alpha.70`
                                //   : theme.appTextColor
                                theme.v3.rawColors.tertiaryNeutral.color1
                              }
                              fontSize={getFontSize()}
                              variant='underlined'
                              borderBottomColor={calculateBorderBottomColor()}
                              customInput={Input}
                              placeholderTextColor={`${theme.appTextColor}`}
                              isReadOnly={
                                !control.isEnabled ||
                                !cardStatus ||
                                (control.label !==
                                  t(
                                    "activation.cardControlTitleOnlineTransactions",
                                  ) &&
                                  onlyVirtual)
                              }
                              value={
                                !control.isEnabled ||
                                !cardStatus ||
                                (control.label !==
                                  t(
                                    "activation.cardControlTitleOnlineTransactions",
                                  ) &&
                                  onlyVirtual)
                                  ? "NA"
                                  : control.dailyLimit
                              }
                              onValueChange={values => {
                                let amount = values?.value
                                inputWidthRef.current = 15 + amount.length * 10
                                //Restricting entering negative amount
                                if ((amount + "").charAt(0) === "-") {
                                  amount = amount * -1
                                }

                                if (amount.length > 9) return false
                                if (INTEGER_REGEX.test(amount)) {
                                  setCardSetting(currCardSetting => ({
                                    ...currCardSetting,
                                    [location]: {
                                      ...currCardSetting[location],
                                      [key]: {
                                        ...currCardSetting[location][key],
                                        dailyLimit: Number(amount),
                                      },
                                    },
                                  }))

                                  trackLimit(
                                    user.programType,
                                    location,
                                    key,
                                    amount, // pass amount
                                  )
                                }
                              }}
                            />
                          </div>
                        </div>
                        <div className='cardSetting-settings-transaction-slider-box'>
                          <div className='cardSetting-settings-transaction-slider-box-inner v3-cardSetting-settings-transaction-slider-box-inner '>
                            <div className='cardSetting-settings-transaction-minMax'>
                              <Slider
                                minValue={0}
                                maxValue={getMaxLimit(control)}
                                value={control.dailyLimit}
                                defaultValue={control.dailyLimit}
                                step={limitSliderStep}
                                onChange={amount => {
                                  // if entered character is valid
                                  if (amount.length > 9) return false
                                  if (INTEGER_REGEX.test(amount)) {
                                    setCardSetting(currCardSetting => ({
                                      ...currCardSetting,
                                      [location]: {
                                        ...currCardSetting[location],
                                        [key]: {
                                          ...currCardSetting[location][key],
                                          dailyLimit: amount,
                                        },
                                      },
                                    }))

                                    trackLimit(
                                      user.programType,
                                      location,
                                      key,
                                      amount, // pass amount
                                    )
                                  }
                                }}
                                filledTrackColor={
                                  !control.isEnabled ||
                                  !cardStatus ||
                                  (control.label !== "Online Transaction" &&
                                    onlyVirtual)
                                    ? theme.v3.rawColors.primaryBase.color5
                                    : theme.v3.rawColors.primary.color1
                                }
                                trackColor={
                                  theme.v3.rawColors.primaryBase.color5
                                }
                                thumbColor={theme.v3.rawColors.leadingWhite}
                              />
                            </div>
                          </div>
                        </div>
                        <div className='cardSetting-settings-transaction-rangeAmount-box'>
                          <div
                            className='cardSetting-settings-transaction-rangeAmount-min'
                            style={{
                              color:
                                !control.isEnabled ||
                                !cardStatus ||
                                (control.label !==
                                  t(
                                    "activation.cardControlTitleOnlineTransactions",
                                  ) &&
                                  onlyVirtual)
                                  ? theme.v3.cssVars.primaryBase.color3
                                  : theme.v3.cssVars.primaryBase.color2,
                            }}
                          >
                            <NumberFormat
                              flex='1'
                              value={"0"}
                              displayType={"text"}
                              thousandSpacing='2s'
                              thousandSeparator={true}
                              prefix={"₹"}
                            />
                          </div>
                          <div
                            className='cardSetting-settings-transaction-rangeAmount-max'
                            style={{
                              color:
                                !control.isEnabled ||
                                !cardStatus ||
                                (control.label !==
                                  t(
                                    "activation.cardControlTitleOnlineTransactions",
                                  ) &&
                                  onlyVirtual)
                                  ? theme.v3.cssVars.primaryBase.color3
                                  : theme.v3.cssVars.primaryBase.color2,
                            }}
                          >
                            <NumberFormat
                              flex='1'
                              value={getMaxLimit(control)}
                              displayType={"text"}
                              thousandSpacing='2s'
                              thousandSeparator={true}
                              prefix={"₹"}
                            />
                          </div>
                        </div>
                        {control.label ===
                          t("activation.cardControlTitleContactless") &&
                        control.isEnabled &&
                        cardStatus &&
                        !onlyVirtual ? (
                          <div
                            className='cardSetting-settings-transaction-contactless-description-box'
                            style={{
                              background:
                                theme.v3.cssVars.tertiaryNeutral.color3,
                            }}
                          >
                            <div
                              className='cardSetting-settings-transaction-contactless-description-text'
                              style={{
                                color: theme.v3.cssVars.tertiaryNeutral.color1,
                              }}
                            >
                              Transactions upto ₹5000 will not require PIN
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}

                        {control.dailyLimit % limitSliderStep !== 0 && (
                          <InfoPromptV3
                            info={`Daily transaction limit must be a multiple of ₹${limitSliderStep}.`}
                            customClassName='v3-cardSettings-info-prompt'
                          />
                        )}
                      </div>
                    </>
                  )}
              </div>
            )
          })}
      </div>
    )
  }

  const getVersionedDisplay = () => {
    return (
      config?.version === PwaVersions.V2 ||
      config?.version === PwaVersions.V2_1 ||
      config?.version === PwaVersions.V3
    )
  }

  return (
    // not using scrollview as react-native-web adds adds an extra div for it
    <div
      className='v3-cardSettings'
      ref={cardSettingsContainerRef}
      style={{
        height: windowDim.height,
        overflow: "auto",
      }}
    >
      {loading ? (
        <div
          className='cardSettings-loader-div'
          style={{
            minHeight: windowDim.height,
            backgroundColor: `${
              config?.version === PwaVersions.V2 ||
              config?.version === PwaVersions.V2_1
                ? theme.backgroundColor
                : theme.v3.cssVars.leadingWhite
            }`,
          }}
        >
          <CardControlsLoader />
        </div>
      ) : error ? (
        <ErrorScreen />
      ) : (
        <div
          className='cardSettings-main-div'
          style={
            getVersionedDisplay()
              ? {
                  backgroundColor: theme.v3.cssVars.leadingWhite,
                  paddingTop: "0px",
                  paddingBottom: "80px",
                  paddingLeft: "0px",
                  paddingRight: "0px",
                  maxWidth: DisplayConfig.MAX_WIDTH,
                }
              : {
                  maxWidth: DisplayConfig.MAX_WIDTH,
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  paddingLeft: "18px",
                  paddingRight: "18px",
                }
          }
        >
          <KeyboardAvoidingView>
            <div className='cardSettings-main-div-1' {...styles.expand}>
              <div className='cardSettings-main-div-2'>
                <div
                  className='cardSettings-header'
                  style={{ maxWidth: DisplayConfig.MAX_WIDTH, width: "100%" }}
                >
                  {config?.version === PwaVersions.V2 ||
                  config?.version === PwaVersions.V3 ? (
                    // <Header
                    //   text='Card Controls'
                    //   onBack={async () => {
                    //     window.history.go(-1)
                    //   }}
                    // />

                    <Header
                      text={"Card Controls"}
                      isHomePage={true}
                      customClassName='header-heading'
                      onBack={async () => {
                        navigate("/")
                      }}
                      rightNavigateURL={isAccountActive && card ? "/" : null}
                    />
                  ) : (
                    <div
                      className='cardSettings-header-v1'
                      style={
                        getVersionedDisplay()
                          ? {
                              backgroundColor: theme.headerBackgroundColor,
                              padding: "18px",
                            }
                          : { padding: "0px" }
                      }
                    >
                      <Link
                        to='/ManageCard'
                        onClick={() => {
                          trackBackClick(user.programType)
                        }}
                      >
                        <ArrowLeft
                          color={
                            getVersionedDisplay() ? theme.titleColor : "#303030"
                          }
                          size='10px'
                          className='cardSettings-header-v1-arrowLeft'
                        />
                      </Link>
                      <div
                        className='cardSettings-header-v1-text'
                        {...styles.cardCotnrolText}
                        style={{
                          color: getVersionedDisplay()
                            ? theme.titleColor
                            : "#303030",
                          fontFamily: theme.fontFamily,
                        }}
                      >
                        {t("settings.cardControlsHeader")}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div
                className='cardSettings-cardStatus-transactionLimit'
                style={
                  getVersionedDisplay()
                    ? { paddingLeft: "18px", paddingRight: "18px" }
                    : {}
                }
              >
                <div
                  className='cardSettings-cardStatus-transactionLimit-innerDiv'
                  style={{ maxWidth: DisplayConfig.MAX_WIDTH }}
                ></div>
              </div>

              <div className='cardSettings-domestic-international-topDiv-container v3-cardSettings-domestic-international-topDiv-container'>
                <div className='cardSettings-domestic-international-topDiv v3-cardSettings-domestic-international-topDiv '>
                  {!isPaylaterOrPrepaidProgram ? (
                    <div
                      className='cardSettings-domestic-international-container v3-cardSettings-domestic-international-container'
                      textAlign={"center"}
                      flexDirection={"row"}
                    >
                      <div
                        className='cardSettings-domestic-div v3-cardSettings-domestic-div'
                        style={{
                          borderBottom: "solid",
                          borderBottomColor: cardStatus
                            ? isDomesticSelected
                              ? theme.v3.cssVars.secondary.color1
                              : theme.v3.cssVars.primaryBase.color4
                            : theme.v3.cssVars.tertiaryNeutral.color2,
                          borderBottomWidth: isDomesticSelected ? "4px" : "1px",
                          backgroundColor: getVersionedDisplay()
                            ? !cardStatus
                              ? grayColor
                              : theme.v3.cssVars.leadingWhite
                            : null,
                        }}
                        onClick={() => setIsDomesticSelected(true)}
                      >
                        <div
                          className='cardSettings-domestic-text'
                          fontWeight={"bold"}
                          style={{
                            color: getVersionedDisplay()
                              ? !cardStatus
                                ? theme.v3.cssVars.tertiaryNeutral.color2
                                : !isDomesticSelected
                                  ? theme.v3.cssVars.tertiaryNeutral.color2
                                  : theme.v3.cssVars.secondary.color1
                              : null,
                          }}
                        >
                          <CardSettingsDomestic
                            color1={
                              getVersionedDisplay()
                                ? !cardStatus
                                  ? theme.v3.cssVars.tertiaryNeutral.color2
                                  : !isDomesticSelected
                                    ? theme.v3.cssVars.tertiaryNeutral.color2
                                    : theme.v3.cssVars.secondary.color1
                                : theme.v3.cssVars.tertiaryNeutral.color2
                            }
                            className={"v3-card-settings-domestic-icon"}
                          />
                          {t("settings.domesticIcon")} &nbsp;
                        </div>
                      </div>

                      <div
                        className='cardSettings-international-div v3-cardSettings-international-div'
                        style={{
                          borderBottom: "solid",
                          borderBottomColor: cardStatus
                            ? !isDomesticSelected
                              ? theme.v3.cssVars.secondary.color1
                              : theme.v3.cssVars.primaryBase.color4
                            : theme.v3.cssVars.tertiaryNeutral.color2,
                          borderBottomWidth: !isDomesticSelected
                            ? "4px"
                            : "1px",
                          backgroundColor: getVersionedDisplay()
                            ? !cardStatus
                              ? grayColor
                              : theme.v3.cssVars.leadingWhite
                            : null,
                        }}
                        onClick={() => setIsDomesticSelected(false)}
                      >
                        <div
                          className='cardSettings-international-text'
                          style={{
                            color: getVersionedDisplay()
                              ? !cardStatus
                                ? theme.appTextColor
                                : isDomesticSelected
                                  ? theme.v3.cssVars.tertiaryNeutral.color2
                                  : theme.v3.cssVars.secondary.color1
                              : null,
                          }}
                        >
                          <CardSettingsInternational
                            color1={
                              getVersionedDisplay()
                                ? !cardStatus
                                  ? theme.v3.cssVars.tertiaryNeutral.color2
                                  : isDomesticSelected
                                    ? theme.v3.cssVars.tertiaryNeutral.color2
                                    : theme.v3.cssVars.secondary.color1
                                : theme.v3.cssVars.tertiaryNeutral.color2
                            }
                            className={"v3-card-settings-international-icon"}
                          />
                          {t("settings.internationalIcon")}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>

              <div className='cardSettings-domIntl-transactions'>
                <div className='cardSettings-domIntl-transactions-inner'>
                  {isDomesticSelected
                    ? getSettings("domestic")
                    : getSettings("international")}
                </div>
              </div>

              {hasStateChanged() && (
                <div className='cardSettings-btn-div'>
                  <div
                    className='v3-cardSettings-btn-save'
                    style={{
                      maxWidth: DisplayConfig.MAX_WIDTH,
                    }}
                  >
                    <div className='v3-cardSettings-btn-info'>
                      <FilledInfoCircle
                        size='15px'
                        color={theme.v3.rawColors.primary.color1}
                      />
                      <div>
                        {t("transactions.dailyTransactionsLimitText", {
                          limitSliderStep,
                        })}
                      </div>
                    </div>
                    <ColorButton
                      text='Save Settings'
                      isLoading={submitLoading}
                      isDisable={false}
                      onPress={handleSubmitClick}
                    />
                    {!(
                      config?.version == PwaVersions.V2 ||
                      config?.version == PwaVersions.V2_1 ||
                      config?.version == PwaVersions.V3
                    ) ? (
                      <WhiteButton
                        text='Cancel'
                        onPress={() => {
                          navigate("/")
                          trackCancelClick(user.programType)
                        }}
                      />
                    ) : null}
                  </div>
                </div>
              )}
            </div>
          </KeyboardAvoidingView>
        </div>
      )}
    </div>
  )
}

export default CardSettings_v3
