import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import DocumentList from "../../../svg/v3/DocumentList"
import ClockBig from "../../../svg/v3/ClockBig"
import QuickActionsItemWithDescription from "./QuickActionsItemWithDescription.js"
import QuickActionsItem from "./QuickActionsItem.js"
import ArrowUp from "../../../svg/arrowUp.js"
import ArrowDown from "../../../svg/arrowDown.js"
import EmiIcon from "../../../svg/emiIcon.js"
import AnalyseSpends from "../../../svg/analyseSpends.js"

const QuickActions = ({ quickActions }) => {
  const { t } = useTranslation()
  const [theme] = useSelector(state => [state.theme])
  const [filteredQuickActions, setFilteredQuickActions] = useState([])
  const [showMore, setShowMore] = useState(false)

  const {
    primary: { color1: primaryColor1 },
    secondary: { color1: secondarColor1 },
    tertiaryNeutral: { color1: tertiaryNeutralColor1 },
  } = theme.v3.rawColors

  const quickActionsConfig = {
    STATEMENT: {
      actionType: "STATEMENT",
      text1: t("ActivitySectionv3.view"),
      text2: t("ActivitySectionv3.statement"),
      description: "Get smart statement summary",
      redirectTo: "/statement",
      icon: <DocumentList color={primaryColor1} />,
    },
    HISTORY: {
      text1: t("ActivitySectionv3.payment"),
      text2: t("ActivitySectionv3.history"),
      redirectTo: "/transactions",
      icon: <ClockBig color={primaryColor1} />,
    },
    ANALYSIS: {
      text1: "Analyse Spends",
      icon: <AnalyseSpends color={primaryColor1} />,
    },
    EMI: {
      text1: "Your EMIs",
      icon: <EmiIcon color={primaryColor1} />,
    },
  }

  useEffect(() => {
    setFilteredQuickActions([
      ...quickActions?.filter(action => {
        return quickActionsConfig[action]
      }),
    ])
  }, [quickActions])

  const getVisibleQuickActions = () => {
    if (showMore) {
      return filteredQuickActions
    } else {
      return filteredQuickActions.slice(0, 3)
    }
  }

  return (
    filteredQuickActions?.length > 0 && (
      <div className='v3-activity-section-actions'>
        {filteredQuickActions?.length > 1 && (
          <div className='v3-activity-section-header'>
            <div className='v3-activity-section-header-text'>
              {t("ActivitySectionv3.quickActions")}
            </div>
            {filteredQuickActions?.length > 3 && (
              <div
                className='v3-activity-section-header-toggle-button'
                onClick={() => setShowMore(!showMore)}
              >
                <div
                  className={
                    showMore
                      ? "v3-activity-section-header-toggle-open"
                      : "v3-activity-section-header-toggle-closed"
                  }
                >
                  {showMore ? "Show Less" : "Show All"}
                </div>
                {showMore ? (
                  <ArrowUp color={tertiaryNeutralColor1} size={"8px"} />
                ) : (
                  <ArrowDown color={secondarColor1} size={"8px"} />
                )}
              </div>
            )}
          </div>
        )}
        <div className='v3-activity-section-actions-body'>
          {filteredQuickActions?.length === 1 && (
            <QuickActionsItemWithDescription
              actionConfig={quickActionsConfig[filteredQuickActions[0]]}
              isOnly
            />
          )}
          {filteredQuickActions?.length === 2 &&
            filteredQuickActions?.map((action, index) => {
              return (
                <QuickActionsItemWithDescription
                  key={index}
                  actionConfig={quickActionsConfig[action]}
                />
              )
            })}
          {filteredQuickActions?.length > 2 && filteredQuickActions?.length < 4
            ? filteredQuickActions?.map((action, index) => {
                return (
                  <QuickActionsItem
                    key={index}
                    actionConfig={quickActionsConfig[action]}
                  />
                )
              })
            : null}
          {filteredQuickActions?.length >= 3 && (
            <div className='v3-activity-section-actions-grid'>
              {getVisibleQuickActions()?.map((action, index) => {
                return (
                  <QuickActionsItem
                    key={index}
                    actionConfig={quickActionsConfig[action]}
                  />
                )
              })}
            </div>
          )}
        </div>
      </div>
    )
  )
}

export default QuickActions
