import { useSelector } from "react-redux"
import { BrandingOnHeaders, HeaderContentAlignment } from "../../../utils/enums"
import Cross from "../../svg/v3/Cross"
import { useEffect, useState } from "react"
import useWindowDimensions from "../../../hooks/useWindowDimensionsWeb"

const BrandHeader_v3 = ({ loaderAnimation, preloader }) => {
  const theme = useSelector(state => state.theme)
  const config = useSelector(state => state.config)
  const screen = useSelector(state => state.screen)
  const session = useSelector(state => state.session)
  const headerConfig = screen?.pwaHeader
  const isAppHeaderEnabled = screen?.pwaHeader?.isAppHeaderEnabled
  const windowDimensions = useWindowDimensions()

  return (
    <>
      {isAppHeaderEnabled && (
        <div className={`v3-header-container`}>
          <div
            style={{ display: session.isCssLoaded ? "flex" : "none" }}
            className='v3-header-top-section'
          >
            {(headerConfig?.brandingOnHeader ===
              BrandingOnHeaders.SINGLE_LOGO ||
              headerConfig?.brandingOnHeader ===
                BrandingOnHeaders.TWO_LOGO) && (
              <div
                className={`v3-header-top-logo ${headerConfig?.logoAlignment === HeaderContentAlignment.CENTER ? "v3-header-top-logo-center" : ""}`}
              >
                <img src={headerConfig?.primaryLogoUrl} alt='primary logo' />
                {headerConfig?.brandingOnHeader ===
                  BrandingOnHeaders.TWO_LOGO && (
                  <>
                    <div className='v3-header-top-logo-seperator'></div>
                    <img
                      src={headerConfig?.secondaryLogoUrl}
                      alt='secondary logo'
                    />
                  </>
                )}
              </div>
            )}
            {headerConfig?.brandingOnHeader === BrandingOnHeaders.TEXT && (
              <div
                className={`v3-header-top-text ${headerConfig?.textAlignment === HeaderContentAlignment.CENTER ? "v3-header-top-text-center" : ""}`}
              >
                {headerConfig?.textContent}
              </div>
            )}
            <div
              onClick={() =>
                (window.location.href = localStorage.getItem("redirectUrl"))
              }
              className='v3-header-top-close'
            >
              <Cross color={theme?.v3?.rawColors?.leadingWhite} />
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default BrandHeader_v3
