import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import moment from "moment"
import LineBreaker from "../../../core/lineBreaker"
import {
  colorPicker,
  captureEvents,
  formatAmount,
} from "../../../../utils/functions"
import { useDispatch, useSelector } from "react-redux"
import usePayButtonText from "../../../../hooks/usePayButtonText"
import {
  AU_BlockCodes,
  EventName,
  Federal_BlockCodes,
  RepaymentTypes,
  cardSections,
} from "../../../../utils/enums"
import { ProgramTypes } from "../../../../utils/constants"
import SparklesLogo from "../../../svg/sparkleSvg"
import ViewArrow from "../../../svg/viewArrow"
import { useNavigate } from "react-router-dom"
import { setCommonState } from "../../../../store/actions/Common"

const StatementInfo = ({
  daysSinceStatement,
  daysLeft,
  currentCycleStartDate,
  currentCycleEndDate,
  lastStatementStartDate,
  lastStatementEndDate,
  navigate,
}) => {
  const { t } = useTranslation()
  const MAX_DAYS = 7
  const [showCTAText, setShowCTAText] = useState(false)

  useEffect(() => {
    const todayDate = new Date()
    todayDate.setHours(0, 0, 0, 0)

    const notificationEndDate = new Date(currentCycleStartDate)
    notificationEndDate.setDate(notificationEndDate.getDate() + MAX_DAYS)

    setShowCTAText(
      todayDate >= new Date(currentCycleStartDate) &&
        todayDate <= notificationEndDate,
    )
  }, [])

  const formatDate = date => {
    return new Date(date).toLocaleDateString("en-US", {
      day: "2-digit",
      month: "short",
    })
  }

  return (
    <>
      {showCTAText && (
        <div
          className='v3-account-summary-highlight v3-account-summary-highlight-fully-paid'
          onClick={() => navigate("/Statement")}
        >
          <div>
            <SparklesLogo color='var(--primary-color-1)' />
            {"  "}
            {t("AccountSummary.statementFor")}
            <span className='v3-account-summary-highlight-days v3-account-summary-highlight-days-fully-paid-days'>
              {" "}
              {formatDate(lastStatementStartDate)} -{" "}
              {formatDate(lastStatementEndDate)}
            </span>
          </div>
          <div>
            {t("ActivitySectionv3.view")} {"  "}
            <ViewArrow />
          </div>
        </div>
      )}
      {daysLeft !== null && (
        <div className='v3-account-summary-highlight'>
          <SparklesLogo />
          {"  "} {t("AccountSummary.newStatementUpcoming")}{" "}
          <span className='v3-account-summary-highlight-days'>
            {daysLeft} {daysLeft === 1 ? "Day" : "Days"}
          </span>
        </div>
      )}
    </>
  )
}

const AccountSummary = ({ props, setIsPaymentGatewayOpen, user }) => {
  const { t } = useTranslation()
  const theme = useSelector(state => state.theme)
  const session = useSelector(state => state.session)
  const featureFlags = useSelector(state => state.featureFlags)
  const screen = useSelector(state => state.screen)
  const textColor = theme.appTextColor
  const ctaPrimaryColor = theme.color1
  const ctaSecondaryColor = theme.color2
  const widgetBackgroundColor = theme.widgetBackgroundColor

  const textBase1 = colorPicker(textColor).primary1
  const textBase2 = colorPicker(textColor).primary2

  const ctaPrimaryColor1 = colorPicker(ctaPrimaryColor).primary1
  const ctaPrimaryColor2 = colorPicker(ctaPrimaryColor).primary2

  const getPayButtonText = usePayButtonText()

  const isRepaymentFlowEnabled = session.repaymentFlow
  const currentCycleStartDate = user?.summary?.account?.currentCycleStartDate
  const currentCycleEndDate = user?.summary?.account?.currentCycleEndDate
  const lastStatementStartDate = user?.summary?.lastStatement?.fromDate
  const lastStatementEndDate = user?.summary?.lastStatement?.toDate
  const [daysLeft, setDaysLeft] = useState(null)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const nextStatementDate = new Date(currentCycleEndDate)
  nextStatementDate.setDate(nextStatementDate.getDate() + 1)

  const today = new Date()
  const daysSinceStatement = Math.floor(
    (today - nextStatementDate) / (1000 * 60 * 60 * 24),
  )

  const totalAmountDue = Number(user.summary.lastStatement?.balanceAmountDue)
  const totalBilledAmount = Number(user.summary.lastStatement?.totalAmountDue)
  const minimumAmountDue = Number(user.summary?.lastStatement?.minimumAmountDue)
  const totalUnbilled =
    Number(props?.creditDetails?.totalCredit) -
    Number(props?.creditDetails?.availableCredit) -
    totalAmountDue

  useEffect(() => {
    if (currentCycleEndDate) {
      const endDate = new Date(currentCycleEndDate)

      const nextStatementDate = new Date(endDate)
      nextStatementDate.setDate(endDate.getDate() + 1)

      const today = new Date()

      const diff = Math.ceil(
        (nextStatementDate - today) / (1000 * 60 * 60 * 24),
      )

      if (diff > 0 && diff <= 3) {
        setDaysLeft(diff)
      } else {
        setDaysLeft(null)
      }
    }
  }, [currentCycleEndDate])

  const handleShowSummaryBottomSheet = () => {
    dispatch(setCommonState({ showSummaryBottomSheet: true }))
  }
  const repayUrlCall = () => {
    if (screen.home.repayments.repaymentType !== RepaymentTypes.S2S_PG) {
      window.location.href = session.repaymentUrl
    } else setIsPaymentGatewayOpen(true)
  }

  const renderMinimumAmountDue = minimumAmountDue => {
    if (
      !minimumAmountDue ||
      totalBilledAmount - minimumAmountDue >= totalAmountDue
    ) {
      return null
    }

    return (
      <div className='v3-activity-section-minimum-amount-due'>
        {t("AccountSummary.minimumAmountDue")}
        {formatAmount(minimumAmountDue)}
      </div>
    )
  }

  const renderPayBillWidget = () => {
    if (Object.keys(props.showReminder).length === 0) {
      return (
        <>
          <div className='v3-activity-section-balance-widget-wrapper'>
            <div
              className='v3-activity-section-summary-credit-balance-section'
              onClick={handleShowSummaryBottomSheet}
            >
              {t("AccountSummary.creditBalance")}
              <span className='v3-activity-section-summary-credit-balance-span'>
                {formatAmount(props.creditDetails.availableCredit)}
              </span>
              <span className='v3-activity-section-summary-credit-arrow-span'>
                <ViewArrow color='var(--System-Palette-neutral-2, #A8A8A8)' />
              </span>
            </div>
            <div className='v3-activity-section-balance'>
              <div className='v3-activity-section-balance-amount-section'>
                <div className='v3-activity-section-balance-amount-section-value'>
                  {t("AccountSummary.noOutsatndingAmt")}
                </div>
                {Number(props.creditDetails.totalCredit) -
                  Number(props.creditDetails.availableCredit) <=
                0 ? (
                  <div className='v3-activity-section-balance-amount-section-due'>
                    {t("AccountSummary.allClear")}
                  </div>
                ) : (
                  <div
                    className='v3-activity-section-balance-amount-section-due'
                    style={{ textAlign: "left" }}
                  >
                    {t("AccountSummary.outstandingAmtToBePaid")}
                  </div>
                )}
                {renderMinimumAmountDue(minimumAmountDue)}
              </div>
              {isRepaymentFlowEnabled && getPayButtonText && (
                <div
                  className='v3-activity-section-balance-amount-cta'
                  onClick={() => {
                    session.disabledFunctionsByBlockCode?.includes(
                      Federal_BlockCodes.PAY_BILL_DISABLE,
                    ) ||
                    session?.disabledFunctionsByBlockCode?.includes(
                      AU_BlockCodes.PAY_BILL_DISABLE,
                    ) ? (
                      <></>
                    ) : (
                      repayUrlCall()
                    )
                    captureEvents({
                      eventName: EventName.PAY_BILL,
                      metadata: { source: EventName.HOME },
                    })
                  }}
                  style={{
                    backgroundColor:
                      Number(props.creditDetails.totalCredit) -
                        Number(props.creditDetails.availableCredit) >
                        0 && theme.v3.cssVars.primaryBase.color1,
                  }}
                >
                  {/* <ColorButton
    text={"Pay Bill"}
    // isDisable={!enableVerify}
    onPress={repayUrlCall}
    // isLoading={submitLoading}
    borderRadius={"12px"}
  /> */}
                  {getPayButtonText}
                </div>
              )}
            </div>
          </div>
          <StatementInfo
            daysSinceStatement={daysSinceStatement}
            daysLeft={daysLeft}
            currentCycleStartDate={currentCycleStartDate}
            currentCycleEndDate={currentCycleEndDate}
            lastStatementStartDate={lastStatementStartDate}
            lastStatementEndDate={lastStatementEndDate}
            navigate={navigate}
          />
        </>
      )
    } else {
      if (props.showReminder.amount <= 0) {
        // fully paid state
        return (
          <>
            <div className='v3-activity-section-balance-widget-wrapper'>
              <div
                className='v3-activity-section-summary-credit-balance-section'
                onClick={handleShowSummaryBottomSheet}
              >
                {t("AccountSummary.creditBalance")}
                <span className='v3-activity-section-summary-credit-balance-span'>
                  {formatAmount(props.creditDetails.availableCredit)}
                </span>
                <span className='v3-activity-section-summary-credit-arrow-span'>
                  <ViewArrow color='var(--System-Palette-neutral-2, #A8A8A8)' />
                </span>
              </div>
              <div className='v3-activity-section-balance'>
                <div className='v3-activity-section-balance-amount-section'>
                  {Number(props.creditDetails.totalCredit) -
                    Number(props.creditDetails.availableCredit) <=
                  0 ? (
                    <>
                      <div
                        className='v3-activity-section-balance-amount-section-value v3-activity-balance-amount-section-fully-paid-value'
                        style={{
                          color: theme.v3.cssVars.tertiaryPositive.color1,
                        }}
                      >
                        {t("AccountSummary.fullyPaid")}
                      </div>
                      <div className='v3-activity-section-balance-amount-section-due'>
                        {t("AccountSummary.noBillsDue")}
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        className='v3-activity-section-balance-amount-section-value'
                        style={{ color: textBase1 }}
                      >
                        {formatAmount(totalUnbilled)}
                      </div>
                      <div
                        className='v3-activity-section-balance-amount-section-due'
                        style={{ textAlign: "left" }}
                      >
                        {t("AccountSummary.unbilledOutsatndingAmt")}
                      </div>
                    </>
                  )}
                  {renderMinimumAmountDue(minimumAmountDue)}
                </div>
                {isRepaymentFlowEnabled && getPayButtonText && (
                  <div
                    className='v3-activity-section-balance-amount-cta'
                    onClick={() => {
                      session.disabledFunctionsByBlockCode?.includes(
                        Federal_BlockCodes.PAY_BILL_DISABLE,
                      ) ||
                      session?.disabledFunctionsByBlockCode?.includes(
                        AU_BlockCodes.PAY_BILL_DISABLE,
                      ) ? (
                        <></>
                      ) : (
                        repayUrlCall()
                      )
                      captureEvents({
                        eventName: EventName.PAY_BILL,
                        metadata: { source: EventName.HOME },
                      })
                    }}
                    style={{
                      backgroundColor:
                        Number(props.creditDetails.totalCredit) -
                          Number(props.creditDetails.availableCredit) ===
                        0
                          ? theme.v3.cssVars.tertiaryNeutral.color2
                          : theme.v3.cssVars.secondary.color1,
                      color: theme.v3.cssVars.leadingWhite,
                    }}
                  >
                    {/* <ColorButton
                    text={"Pay Bill"}
                    // isDisable={!enableVerify}
                    onPress={repayUrlCall}
                    // isLoading={submitLoading}
                    borderRadius={"12px"}
                  /> */}
                    {getPayButtonText}
                  </div>
                )}
              </div>
            </div>
            <StatementInfo
              daysSinceStatement={daysSinceStatement}
              daysLeft={daysLeft}
              currentCycleStartDate={currentCycleStartDate}
              currentCycleEndDate={currentCycleEndDate}
              lastStatementStartDate={lastStatementStartDate}
              lastStatementEndDate={lastStatementEndDate}
              navigate={navigate}
            />
          </>
        )
      } else {
        if (moment() > moment(props.showReminder.dueDate)) {
          //overdue state
          return (
            <>
              <div className='v3-activity-section-balance-widget-wrapper'>
                <div
                  className='v3-activity-section-summary-credit-balance-section'
                  onClick={handleShowSummaryBottomSheet}
                >
                  {t("AccountSummary.creditBalance")}
                  <span className='v3-activity-section-summary-credit-balance-span'>
                    {formatAmount(props.creditDetails.availableCredit)}
                  </span>
                  <span className='v3-activity-section-summary-credit-arrow-span'>
                    <ViewArrow color='var(--System-Palette-neutral-2, #A8A8A8)' />
                  </span>
                </div>
                <div className='v3-activity-section-balance'>
                  <div className='v3-activity-section-balance-amount-section'>
                    <div
                      className='v3-activity-section-balance-amount-section-value'
                      style={{ color: textBase1 }}
                    >
                      {formatAmount(props.showReminder.amount)}
                    </div>
                    <div className='v3-activity-section-balance-amount-section-overdue'>
                      {t("AccountSummary.billOverdueOn", {
                        date: moment(props.showReminder.dueDate).format(
                          "DD MMM 'YY",
                        ),
                      })}
                    </div>
                    {renderMinimumAmountDue(minimumAmountDue)}
                  </div>
                  {isRepaymentFlowEnabled && getPayButtonText && (
                    <div
                      className='v3-activity-section-balance-amount-cta'
                      style={{
                        background: theme.v3.cssVars.tertiaryNegative.color1,
                      }}
                      onClick={() => {
                        session.disabledFunctionsByBlockCode?.includes(
                          Federal_BlockCodes.PAY_BILL_DISABLE,
                        ) ||
                        session?.disabledFunctionsByBlockCode?.includes(
                          AU_BlockCodes.PAY_BILL_DISABLE,
                        ) ? (
                          <></>
                        ) : (
                          repayUrlCall()
                        )
                        captureEvents({
                          eventName: EventName.PAY_BILL,
                          metadata: { source: EventName.HOME },
                        })
                      }}
                    >
                      {/* <ColorButton
                text={"Pay Bill"}
                // isDisable={!enableVerify}
                onPress={repayUrlCall}
                // isLoading={submitLoading}
                borderRadius={"12px"}
              /> */}
                      {getPayButtonText}
                    </div>
                  )}
                </div>
              </div>
              <StatementInfo
                daysSinceStatement={daysSinceStatement}
                daysLeft={daysLeft}
                currentCycleStartDate={currentCycleStartDate}
                currentCycleEndDate={currentCycleEndDate}
                lastStatementStartDate={lastStatementStartDate}
                lastStatementEndDate={lastStatementEndDate}
                navigate={navigate}
              />
            </>
          )
        } else {
          if (props.showReminder.amount !== props.showReminder.totalAmountDue) {
            //partially paid
            return (
              <>
                <div
                  className='v3-activity-section-balance-widget-wrapper'
                  style={{ background: widgetBackgroundColor }}
                >
                  <div
                    className='v3-activity-section-summary-credit-balance-section'
                    onClick={handleShowSummaryBottomSheet}
                  >
                    {t("AccountSummary.creditBalance")}
                    <span className='v3-activity-section-summary-credit-balance-span'>
                      {formatAmount(props.creditDetails.availableCredit)}
                    </span>
                    <span className='v3-activity-section-summary-credit-arrow-span'>
                      <ViewArrow color='var(--System-Palette-neutral-2, #A8A8A8)' />
                    </span>
                  </div>
                  <div className='v3-activity-section-balance'>
                    <div className='v3-activity-section-balance-amount-section'>
                      <div
                        className='v3-activity-section-balance-amount-section-value'
                        style={{ color: textBase1 }}
                      >
                        {formatAmount(props.showReminder.amount)}
                      </div>
                      <div className='v3-activity-section-balance-amount-section-due'>
                        {t("AccountSummary.remainingDueOn", {
                          date: moment(props.showReminder.dueDate).format(
                            "DD MMM 'YY",
                          ),
                        })}
                      </div>
                      {renderMinimumAmountDue(minimumAmountDue)}
                    </div>
                    {isRepaymentFlowEnabled && getPayButtonText && (
                      <div
                        className='v3-activity-section-balance-amount-cta'
                        onClick={() => {
                          session.disabledFunctionsByBlockCode?.includes(
                            Federal_BlockCodes.PAY_BILL_DISABLE,
                          ) ||
                          session?.disabledFunctionsByBlockCode?.includes(
                            AU_BlockCodes.PAY_BILL_DISABLE,
                          ) ? (
                            <></>
                          ) : (
                            repayUrlCall()
                          )
                          captureEvents({
                            eventName: EventName.PAY_BILL,
                            metadata: { source: EventName.HOME },
                          })
                        }}
                      >
                        {/* <ColorButton
                    text={"Pay Bill"}
                    // isDisable={!enableVerify}
                    onPress={repayUrlCall}
                    // isLoading={submitLoading}
                    borderRadius={"12px"}
                  /> */}
                        {getPayButtonText}
                      </div>
                    )}
                  </div>
                </div>
                <StatementInfo
                  daysSinceStatement={daysSinceStatement}
                  daysLeft={daysLeft}
                  currentCycleStartDate={currentCycleStartDate}
                  currentCycleEndDate={currentCycleEndDate}
                  lastStatementStartDate={lastStatementStartDate}
                  lastStatementEndDate={lastStatementEndDate}
                  navigate={navigate}
                />
              </>
            )
          } else {
            // general state (not partially paid)
            return (
              <>
                <div className='v3-activity-section-balance-widget-wrapper'>
                  <div
                    className='v3-activity-section-summary-credit-balance-section'
                    onClick={handleShowSummaryBottomSheet}
                  >
                    {t("AccountSummary.creditBalance")}
                    <span className='v3-activity-section-summary-credit-balance-span'>
                      {formatAmount(props.creditDetails.availableCredit)}
                    </span>
                    <span className='v3-activity-section-summary-credit-arrow-span'>
                      <ViewArrow color='var(--System-Palette-neutral-2, #A8A8A8)' />
                    </span>
                  </div>
                  <div className='v3-activity-section-balance'>
                    <div className='v3-activity-section-balance-amount-section'>
                      <div
                        className='v3-activity-section-balance-amount-section-value'
                        style={{ color: textBase1 }}
                      >
                        {formatAmount(props.showReminder.amount)}
                      </div>
                      <div className='v3-activity-section-balance-amount-section-due'>
                        {t("AccountSummary.billDueOn")}{" "}
                        {moment(props.showReminder.dueDate).format(
                          "DD MMM 'YY",
                        )}
                      </div>
                      {renderMinimumAmountDue(minimumAmountDue)}
                    </div>
                    {isRepaymentFlowEnabled && getPayButtonText && (
                      <div
                        className='v3-activity-section-balance-amount-cta'
                        onClick={() => {
                          session.disabledFunctionsByBlockCode?.includes(
                            Federal_BlockCodes.PAY_BILL_DISABLE,
                          ) ||
                          session?.disabledFunctionsByBlockCode?.includes(
                            AU_BlockCodes.PAY_BILL_DISABLE,
                          ) ? (
                            <></>
                          ) : (
                            repayUrlCall()
                          )
                          captureEvents({
                            eventName: EventName.PAY_BILL,
                            metadata: { source: EventName.HOME },
                          })
                        }}
                      >
                        {/* <ColorButton
                text={"Pay Bill"}
                // isDisable={!enableVerify}
                onPress={repayUrlCall}
                // isLoading={submitLoading}
                borderRadius={"12px"}
              /> */}
                        {getPayButtonText}
                      </div>
                    )}
                  </div>
                </div>
                <StatementInfo
                  daysSinceStatement={daysSinceStatement}
                  daysLeft={daysLeft}
                  currentCycleStartDate={currentCycleStartDate}
                  currentCycleEndDate={currentCycleEndDate}
                  lastStatementStartDate={lastStatementStartDate}
                  lastStatementEndDate={lastStatementEndDate}
                  navigate={navigate}
                />
              </>
            )
          }
        }
      }
    }
  }

  return (
    <div
      style={{
        background: theme.v3.cssVars.primary.color5,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        gap: "20px",
        padding: "28px 0",
      }}
    >
      <div className='v3-activity-section-summary'>
        <div className='v3-activity-section-title'>
          {t("AccountSummary.accountSummary")}
        </div>
        <div className='v3-activity-section-summary-value-section'>
          <div className='v3-activity-section-summary-value-section-title'>
            {t("AccountSummary.availableCredit")}
          </div>
          <div className='v3-activity-section-summary-value-section-value'>
            {formatAmount(props.creditDetails.availableCredit)}/
            <span className='v3-activity-section-summary-value-section-value-sub'>
              {formatAmount(props.creditDetails.totalCredit)}
            </span>
          </div>
        </div>
        <div className='v3-activity-section-summary-value-section'>
          <div className='v3-activity-section-summary-value-section-title'>
            {user.programType === ProgramTypes.CREDIT_CARD
              ? "Total Outstanding"
              : "Used Credit"}
          </div>
          {Number(props.creditDetails.totalCredit) -
            Number(props.creditDetails.availableCredit) >=
          0 ? (
            <div className='v3-activity-section-summary-value-section-value'>
              {formatAmount(
                Number(props.creditDetails.totalCredit) -
                  Number(props.creditDetails.availableCredit),
              )}
            </div>
          ) : (
            <div
              className='v3-activity-section-summary-value-section-value'
              style={{ color: theme.v3.cssVars.tertiaryPositive.color1 }}
            >
              {formatAmount(
                Number(props.creditDetails.totalCredit) -
                  Number(props.creditDetails.availableCredit),
                true,
              )}
            </div>
          )}
        </div>
      </div>
      {/* <LineBreaker /> */}
      {renderPayBillWidget()}
    </div>
  )
}

export default AccountSummary
