import { BottomSheet } from "react-spring-bottom-sheet"
import crossIcon from "../../../../assets/images/v3/closeBtn.svg"
import { formatAmount } from "../../../../utils/functions"
import { useDispatch, useSelector } from "react-redux"

import React, { useState } from "react"
import { setCommonState } from "../../../../store/actions/Common"
import ViewBillIcon from "../../../svg/v3/latestBillIcon"
import ViewArrow from "../../../svg/viewArrow"
import UnbilledIcon from "../../../svg/v3/unbilledIcon"
import { useNavigate } from "react-router-dom"
import Stroke from "../../../svg/Strokes"
import { t } from "i18next"

const SummaryBottomSheet = ({
  isOpen,
  onClose,
  creditDetails,
  cardDetails,
}) => {
  const user = useSelector(state => state.user)
  const approvedCreditLimit = user?.summary?.account?.approvedCreditLimit
  const availableCreditLimit = user?.summary?.account?.approvedCreditLimit
  const navigate = useNavigate()
  const [currentStatement, setCurrentStatement] = useState(
    user.summary.lastStatement,
  )
  const dispatch = useDispatch()
  const handleClose = () => {
    dispatch(setCommonState({ showSummaryBottomSheet: false }))
  }
  const totalAmountDue = Number(currentStatement?.balanceAmountDue)
  const totalUnbilled =
    Number(creditDetails.totalCredit) -
    Number(creditDetails.availableCredit) -
    totalAmountDue

  return (
    <div style={{ maxWidth: "500px" }}>
      <BottomSheet open={isOpen} onDismiss={onClose}>
        <div
          className='v3-bottomDrawer-nudges-container'
          style={{ backgroundColor: "white", borderRadius: "16px" }}
        >
          <div className='v3-bottomDrawer-nudges-header'>
            <div className='v3-summary-bottomSheet-title'>
              {t("AccountSummary.cardLimitInfo")}
            </div>
            <span
              className='v3-bottomDrawer-nudges-close-btn'
              onClick={handleClose}
            >
              <img src={crossIcon} />
            </span>
          </div>
          <div className='v3-summary-bottomSheet-content'>
            <div className='v3-summary-bottomSheet-subtitle'>
              {t("AccountSummary.availableCredit")}
            </div>
            <div className='v3-summary-bottomSheet-value'>
              <span className='v3-summary-bottomSheet-section-value-sub'>
                {formatAmount(creditDetails.availableCredit)}
              </span>
              /{formatAmount(creditDetails.totalCredit)}
            </div>
          </div>
          <div className='v3-summary-bottomSheet-statusBar'>
            <div
              className='v3-summary-bottomSheet-statusBar-filled'
              style={{
                width: `${(creditDetails.availableCredit / creditDetails.totalCredit) * 100}%`,
              }}
            ></div>
          </div>
          <div className='v3-summary-bottomSheet-stroke'>
            <Stroke />
          </div>

          <div className='v3-summary-bottomSheet-total-outStanding-transactions'>
            <div className='v3-summary-bottomSheet-total-outStanding-container'>
              <div className='v3-summary-bottomSheet-subtitle'>
                {t("AccountSummary.totalOutstanding")}
              </div>
              <div className='v3-summary-bottomSheet-total-outStanding-value'>
                {formatAmount(
                  Number(creditDetails.totalCredit) -
                    Number(creditDetails.availableCredit),
                  true,
                )}
              </div>
            </div>
            {totalAmountDue >= 1 && (
              <div className='v3-summary-bottomSheet-latest-bill'>
                <div className='v3-summary-bottomSheet-latest-bill-container'>
                  <div className='v3-summary-bottomSheet-latest-bill-icon'>
                    <ViewBillIcon
                      backgroundColor='var(--System-Palette-negative-feedback-3, #FEF2F2)'
                      iconColor='var(--System-Palette-negative-feedback-1, #E72B2B)'
                    />
                  </div>
                  <div className='v3-summary-bottomSheet-latest-bill-text'>
                    <div className='v3-summary-bottomSheet-latest-bill-text-title'>
                      {t("AccountSummary.latestBill")}
                    </div>
                    <div
                      className='v3-summary-bottomSheet-latest-bill-text-sub'
                      onClick={() => navigate("/Statement")}
                    >
                      <div className='v3-summary-bottomSheet-latest-bill-text-statement'>
                        {t("AccountSummary.viewStatement")}
                      </div>
                      <div className='v3-summary-bottomSheet-latest-bill-arrow'>
                        <ViewArrow color='#25314C' />
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className='v3-summary-bottomSheet-latest-bill-value'>
                    {formatAmount(currentStatement?.totalAmountDue)}
                  </div>
                  {currentStatement?.totalAmountDue !==
                    currentStatement?.balanceAmountDue && (
                    <>
                      <div className='v3-summary-bottomSheet-latest-bill-partially-paid-value'>
                        -{" "}
                        {formatAmount(
                          currentStatement?.totalAmountDue -
                            currentStatement?.balanceAmountDue,
                        )}
                      </div>
                      <div className='v3-summary-bottomSheet-latest-bill-partially-paid'>
                        {t("AccountSummary.partiallyPaid")}
                      </div>
                    </>
                  )}
                </div>
              </div>
            )}
            {totalUnbilled >= 1 && (
              <div className='v3-summary-bottomSheet-unbilled'>
                <div className='v3-summary-bottomSheet-latest-bill-container'>
                  <div className='v3-summary-bottomSheet-latest-bill-icon'>
                    <UnbilledIcon
                      backgroundColor='var(--Colors-PRIMARY-BASE-5, #F0F2F4)'
                      iconColor='var(--Colors-PRIMARY-BASE-1, #0D2950)'
                    />
                  </div>
                  <div className='v3-summary-bottomSheet-latest-bill-text'>
                    <div className='v3-summary-bottomSheet-latest-bill-text-title'>
                      {t("AccountSummary.Unbilled")}
                    </div>
                    <div
                      className='v3-summary-bottomSheet-latest-bill-text-sub'
                      onClick={() => navigate("/Transactions")}
                    >
                      <div className='v3-summary-bottomSheet-latest-bill-text-statement'>
                        {t("AccountSummary.seeTransactions")}
                      </div>
                      <div className='v3-summary-bottomSheet-latest-bill-arrow'>
                        <ViewArrow color='#25314C' />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='v3-summary-bottomSheet-latest-bill-value'>
                  {formatAmount(totalUnbilled)}
                </div>
              </div>
            )}
          </div>
        </div>
      </BottomSheet>
    </div>
  )
}

export default SummaryBottomSheet
