import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import homeComponentTranslations from "./Home/component_translations.json"
import authComponentsTranslations from "./authComponents.json"
import rewardsAndOffersComponents from "./rewardsAndOffersComponents.json"
import coreComponents from "./coreComponents.json"
import manageCardComponents from "./manageCardComponents.json"
import cardDetailsComponents from "./cardDetailsComponents.json"
import cardSettingsComponents from "./cardSettingsComponents.json"
import commonComponents from "./common.json"
import blockedScreenComponents from "./blockedScreenComponents.json"

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  .init({
    debug: true,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          ...homeComponentTranslations,
          ...authComponentsTranslations,
          ...rewardsAndOffersComponents,
          ...coreComponents,
          ...manageCardComponents,
          ...cardDetailsComponents,
          ...cardSettingsComponents,
          ...commonComponents,
          ...blockedScreenComponents,
        },
      },
    },
  })

export default i18n
