import React from "react"
import StyledCheckIcon from "../../../../../../assets/images/v3/checkIconStyled.svg"
import { useTranslation } from "react-i18next"
import { offerType } from "../../../../../../utils/constants"

const RenderOptInStatus = ({ isOptedIn, benefitType }) => {
  const { t } = useTranslation()
  if (!isOptedIn) {
    return (
      <div className='v3-AllCampaignOffers-cvpOffersContainer-offerCard-opt-in-container'>
        {t("offers.optInText")}
      </div>
    )
  } else {
    return (
      <div className='v3-AllCampaignOffers-cvpOffersContainer-offerCard-opted-in-container'>
        <img src={StyledCheckIcon} />
        {benefitType !== offerType.PROGRAM_OFFERS && (
          <div className='v3-AllCampaignOffers-campaignOffersContainer-offerCard-opted-in-container-text'>
            {t("offers.optedOfferText")}
          </div>
        )}
      </div>
    )
  }
}

export default RenderOptInStatus
